import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/constants'
import { formatUrl } from '../../../helpers/functions'
import useGetOrganization from '../../../hooks/useGetOrganization'
import { PrimaryButton } from '../../Form/Button'
import FormLabel from '../../Form/FormLabel'
import FormRow from '../../Form/FormRow'
import Heading from '../../Heading'
import Layout from '../../Layout'
import Card from '../../Layout/Card'
import Input from '../../_shared/Input'
import OrganizationSettingsNav from './OrganizationSettingsNav'

export default function OrganizationSettingsGeneral() {
  const { organizationSlug } = useParams()
  const { isLoading, data: organizationDetails } =
    useGetOrganization(organizationSlug)
  const [request, setRequest] = useState({
    name: '',
    slug: '',
    description: '',
  })

  useEffect(() => {
    if (!organizationDetails) {
      return
    }

    setRequest({
      name: organizationDetails?.name,
      slug: organizationDetails?.slug,
      description: organizationDetails?.description || '',
    })
  }, [organizationDetails])

  const breadcrumbs = [
    {
      name: organizationDetails?.name,
      path: formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, organizationSlug),
    },
    {
      name: 'Settings',
    },
  ]

  if (isLoading || !organizationDetails) {
    return null
  }

  return (
    <Layout
      title={organizationDetails.name}
      breadcrumbs={breadcrumbs}
      nav={
        <OrganizationSettingsNav
          organizationSlug={organizationSlug}
          currentRoute={ROUTES.ORGANIZATION_SETTINGS.GENERAL}
        />
      }
    >
      {/* TODO: */}
      <form onSubmit={() => {}}>
        <Card
          header={<Heading size={3}>General</Heading>}
          footer={
            <div className="text-right">
              <PrimaryButton disabled>Save</PrimaryButton>
            </div>
          }
        >
          <div className="space-y-6">
            <FormRow
              label={<FormLabel htmlFor="name">Organization name</FormLabel>}
            >
              <Input
                name="name"
                id="name"
                placeholder="My Business Inc."
                onChange={(e) =>
                  setRequest((r) => ({
                    ...r,
                    name: e.target.value,
                  }))
                }
                value={request.name}
                disabled
                required
              />
            </FormRow>

            <FormRow
              label={
                <FormLabel htmlFor="slug">Organization identifier</FormLabel>
              }
            >
              <Input
                name="slug"
                id="slug"
                placeholder="my-business-inc"
                onChange={(e) =>
                  setRequest((r) => ({
                    ...r,
                    slug: e.target.value,
                  }))
                }
                value={request.slug}
                disabled
                required
              />
            </FormRow>

            <FormRow
              contentSpan={3}
              label={<FormLabel htmlFor="description">Description</FormLabel>}
            >
              <textarea
                id="description"
                name="description"
                rows={3}
                className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                onChange={(e) =>
                  setRequest((r) => ({
                    ...r,
                    description: e.target.value,
                  }))
                }
                value={request.description}
                disabled
              />
            </FormRow>
          </div>
        </Card>
      </form>
    </Layout>
  )
}
