import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { formatUrl, slugifyName } from '../../helpers/functions'
import { AWS_REGIONS, COLOR, ROUTES } from '../../helpers/constants'
import UserContext from '../../context/UserContext'
import StackSelector from './StackSelector'
import ProjectStackEnum from '../../enums/ProjectStackEnum'
import ProjectService from '../../services/ProjectService'
import NotificationTypeEnum from '../../enums/NotificationTypeEnum'
import locale from '../../translations/en'
import NotificationContext from '../../context/NotificationContext'
import ColorPicker from './ColorPicker'
import AWSRegionEnum from '../../enums/AWSRegionEnum'
import SelectDropdown from '../SelectDropdown'
import Slideout from '../_shared/Slideout'
import useGetMetadata from '../../hooks/useGetMetadata'
import Input from '../_shared/Input'
import FormRow from '../Form/FormRow'
import FormLabel from '../Form/FormLabel'

export default function CreateProjectSlideout({ open, onClose }) {
  const projectService = ProjectService.get()
  const {
    userContext: { selectedOrg },
  } = useContext(UserContext)
  const navigate = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const { isLoading: isLoadingStacks, data: metadata } = useGetMetadata()
  const stacks = metadata?.data?.stacks || []

  const [isLoading, setIsLoading] = useState(false)
  const [request, setRequest] = useState({
    name: '',
    slug: '',
    owner_name: '',
    aws_region: AWSRegionEnum.USEast2,
    color: COLOR.BLUE,
    description: '',
    stack_id: null,
    organization_id: '',
  })

  const createProject = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const createResponse = await projectService
      .createProject(request)
      .catch((e) => {
        showNotification(
          NotificationTypeEnum.Error,
          locale.project.errorCreate,
          e?.response?.data?.message,
          5000
        )
      })

    if (createResponse?.success) {
      showNotification(
        NotificationTypeEnum.Success,
        locale.project.successCreate,
        locale.project.successCreateSubtitle,
        3000
      )

      navigate(
        formatUrl(
          ROUTES.PROJECT_CONFIGURE.CONFIGURATION,
          selectedOrg?.slug,
          request.slug
        )
      )

      return
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!selectedOrg) {
      return
    }

    setRequest((r) => ({
      ...r,
      owner_name: selectedOrg?.name,
      organization_id: selectedOrg?.id,
    }))
  }, [selectedOrg])

  useEffect(() => {
    if (stacks?.length > 0) {
      setRequest((r) => ({
        ...r,
        stack_id: stacks[0].id,
      }))
    }
  }, [stacks])

  return (
    <Slideout
      open={open}
      onClose={onClose}
      title="New project"
      subtitle="Get started by filling in the information below to create your new project."
      onSubmit={createProject}
      isLoading={isLoading}
    >
      <div className="py-6 px-4 space-y-6 sm:p-6">
        <div>
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="name">Project name</FormLabel>}
          >
            <Input
              name="name"
              id="name"
              placeholder="My API Service"
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  name: e.target.value,
                }))
              }
              value={request.name}
              onBlur={() => {
                if (request.slug.length === 0) {
                  setRequest((r) => ({
                    ...r,
                    slug: slugifyName(request.name),
                  }))
                }
              }}
              required
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="slug">Project identifier</FormLabel>}
          >
            <Input
              name="slug"
              id="slug"
              placeholder="my-api-service"
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  slug: e.target.value,
                }))
              }
              value={request.slug}
              required
            />
            <p className="mt-2 text-sm text-gray-500">Unique URL identifier</p>
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="owner_name">Project owner</FormLabel>}
          >
            <Input
              name="owner_name"
              id="owner_name"
              placeholder="Your Name"
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  owner_name: e.target.value,
                }))
              }
              value={request.owner_name}
              required
            />
            <p className="mt-2 text-sm text-gray-500">
              Your AWS resources will be tagged with this name
            </p>
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={3}
            label={<FormLabel htmlFor="aws_region">AWS region</FormLabel>}
          >
            <SelectDropdown
              values={AWS_REGIONS}
              selectedValue={request.aws_region}
              onChange={(value) =>
                setRequest((r) => ({
                  ...r,
                  aws_region: value,
                }))
              }
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={3}
            label={<FormLabel htmlFor="color">Color identifier</FormLabel>}
          >
            <ColorPicker
              selectedColor={request.color}
              onChange={(value) => {
                setRequest((r) => ({
                  ...r,
                  color: value,
                }))
              }}
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="description">Description</FormLabel>}
          >
            <textarea
              id="description"
              name="description"
              rows={3}
              className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  description: e.target.value,
                }))
              }
              value={request.description}
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="stack">Application type</FormLabel>}
          >
            {request.stack_id && (
              <StackSelector
                stacks={stacks}
                selectedStack={request.stack_id}
                onChange={(stack_id) =>
                  setRequest((r) => ({
                    ...r,
                    stack_id,
                  }))
                }
                readOnly // Until EKS option becomes available
              />
            )}
            <a
              href="/"
              className="group inline-flex mt-3 items-center text-sm text-gray-500 hover:text-gray-900"
            >
              <QuestionMarkCircleIcon
                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              <span className="ml-2">Learn more about application types</span>
            </a>
          </FormRow>
        </div>
      </div>
    </Slideout>
  )
}
