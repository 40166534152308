import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import axiosMiddleware from './helpers/axiosMiddleware'
import App from './components/App'
import './sass/index.scss'
import Routes from './routes'

const rootElement = document.getElementById('root')
const queryClient = new QueryClient()

axiosMiddleware()

render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App>
        <Routes />
      </App>
    </BrowserRouter>
  </QueryClientProvider>,
  rootElement
)
