/**
 * Translations for this enum are stored in /src/translations/en.js
 * Must map to the same enum on the API side
 */
enum RunEventStatusEnum {
  InProgress = 'in_progress',
  Success = 'success',
  Error = 'error',
  Skipped = 'skipped',
}

export default RunEventStatusEnum
