import { useContext, useEffect, useMemo, useState } from 'react'
import ProjectConfigureStepEnum from '../../../enums/ProjectConfigureStepEnum'
import ProjectConfigureContext from '../../../context/ProjectConfigureContext'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  COLOR,
  ROUTES,
} from '../../../helpers/constants'
import { classNames, formatUrl } from '../../../helpers/functions'
import { PrimaryButton, SecondaryButton } from '../../Form/Button'
import Alert from '../../_shared/Alert'
import Toggle from '../../Toggle'
import { LockClosedIcon } from '@heroicons/react/solid'
import githubLogo from '../../../images/github-logo.svg'
import SelectDropdown from '../../SelectDropdown'
import { PlusIcon, RefreshIcon } from '@heroicons/react/outline'
import useListSCMOrganizations from '../../../hooks/useListSCMOrganizations'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useGetMetadata from '../../../hooks/useGetMetadata'
import Input from '../../_shared/Input'
import FormRow from '../../Form/FormRow'
import FormLabel from '../../Form/FormLabel'
import { Grid, Col } from '../../Layout/Grid'
import NotificationContext from '../../../context/NotificationContext'
import NotificationTypeEnum from '../../../enums/NotificationTypeEnum'
import locale from '../../../translations/en'

export default function AddOns({ currentStep, onStepChange }) {
  const [isLoading, setIsLoading] = useState(BUTTON_STYLE.NONE)
  const navigate = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const { organizationSlug, projectSlug } = useParams()
  const {
    project: {
      // TODO: Use project only with *.*
      organization_id,
      features,
      domain_configuration,
      database_configuration,
      scm_configuration,
      stack_id,
      deployed_runs_exists,
    },
  } = useContext(ProjectConfigureContext)
  const [featureToggles, setFeatureToggles] = useState(null)
  const [domainConfiguration, setDomainConfiguration] = useState(null)
  const [databaseConfiguration, setDatabaseConfiguration] = useState(null)
  const [sourceControlConfiguration, setSourceControlConfiguration] =
    useState(null)

  const {
    isLoading: isFetchingSourceControlOrgs,
    data: listSourceControlOrgs,
    refetch: refetchSourceControlOrgs,
  } = useListSCMOrganizations(organization_id)
  const { isLoading: isLoadingMetadata, data: metadata } = useGetMetadata()
  const { success: successFetchingSourceControlOrgs, data: sourceControlOrgs } =
    listSourceControlOrgs || {}
  // TODO: REMOVE static github text/icons
  const sourceControlOrgOptions = sourceControlOrgs?.map((org) => ({
    id: org.id,
    name: (
      <span className="inline-flex items-center">
        <img className="mr-1.5 w-4 h-4" src={org.avatar_url} alt={org.name} />
        {org.name}
      </span>
    ),
    value: org.id,
  }))
  const currentStack = useMemo(
    () => metadata?.data?.stacks.find(({ id }) => id === stack_id),
    [metadata?.data, stack_id]
  )
  const frameworkOptions = useMemo(
    () =>
      currentStack?.frameworks.map((framework) => ({
        id: framework.id,
        name: framework.name,
        value: framework.id,
      })),
    [currentStack]
  )

  const validateForm = () => {
    if (featureToggles?.create_domain_record) {
      if (domainConfiguration.domain_name == '' || domainConfiguration.aws_hosted_zone == '') {
        return false;
      }
    }

    return true;
  }

  const goToPrevious = async () => {
    setIsLoading(BUTTON_STYLE.SECONDARY)

    const projectSaveResponse = await onStepChange(
      ProjectConfigureStepEnum.Configuration,
      {
        features: featureToggles,
        domain_configuration: domainConfiguration,
        database_configuration: databaseConfiguration,
        scm_configuration: sourceControlConfiguration,
      }
    )

    if (!projectSaveResponse) {
      setIsLoading(BUTTON_STYLE.NONE)
    }
  }

  const goToNext = async () => {
    setIsLoading(BUTTON_STYLE.PRIMARY)

    if (!validateForm()) {
      showNotification(
        NotificationTypeEnum.Error,
        locale.project.errorUpdate,
        locale.project.errorUpdateRequiredFields,
        5000
      )

      return;
    }

    const projectSaveResponse = await onStepChange(null, {
      features: featureToggles,
      domain_configuration: domainConfiguration,
      database_configuration: databaseConfiguration,
      scm_configuration: sourceControlConfiguration,
    })

    navigate(formatUrl(ROUTES.PROJECT_PREVIEW, organizationSlug, projectSlug))

    if (!projectSaveResponse) {
      setIsLoading(BUTTON_STYLE.NONE)
    }
  }

  // Set defaults on the form
  useEffect(() => {
    const defaultSourceControlOrg =
      !Boolean(scm_configuration?.scm_organization_id) &&
        sourceControlOrgs?.length > 0
        ? sourceControlOrgs[0].id
        : null

    setFeatureToggles({
      ...features,
      create_domain_record: Boolean(features?.create_domain_record),
      create_database: Boolean(features?.create_database),
      create_repository: Boolean(features?.create_repository),
    })

    setDomainConfiguration({
      ...domain_configuration,
      domain_name: domain_configuration?.domain_name || '',
      aws_hosted_zone: domain_configuration?.aws_hosted_zone || '',
      create_ssl: Boolean(domain_configuration?.create_ssl),
      create_www_record: Boolean(domain_configuration?.create_www_record),
    })

    // TODO: Make functional
    setDatabaseConfiguration({
      ...database_configuration,
      engine: database_configuration?.engine || 'mysql', // TODO: Enum
      engine_version: database_configuration?.engine_version || '8.0', // TODO: Enum
      instance_type: database_configuration?.instance_type || 'db.t3.micro', // TODO: Enum
      db_name: database_configuration?.db_name || 'main',
      db_username: database_configuration?.db_username || 'admin',
    })

    setSourceControlConfiguration({
      ...scm_configuration,
      repo_name: scm_configuration?.repo_name || projectSlug,
      scm_organization: null,
      scm_organization_id:
        scm_configuration?.scm_organization_id || defaultSourceControlOrg,
      framework: null,
      framework_id:
        scm_configuration?.framework?.id || frameworkOptions?.[0]?.id,
      framework_version: null,
      // framework_version_id: undefined,
    })
  }, [
    features,
    domain_configuration,
    database_configuration,
    scm_configuration,
    sourceControlOrgs,
    frameworkOptions,
  ])

  return (
    <form action="#" method="POST">
      {/* Source Control */}
      <div className="bg-white shadow sm:rounded-md mb-2">
        <div className="py-6 px-4 space-y-6 sm:p-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Source Control
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Create a new GitHub repository to automatically deploy your code
                with CICD
              </p>
            </div>
            <div className="flex items-center">
              <span
                className={classNames(
                  'mr-2 text-sm font-medium',
                  featureToggles?.create_repository
                    ? 'text-blue-600'
                    : 'text-gray-400'
                )}
              >
                {featureToggles?.create_repository ? 'Enabled' : 'Disabled'}
              </span>
              <Toggle
                enabled={featureToggles?.create_repository}
                onChange={(value) => {
                  setFeatureToggles((f) => ({
                    ...f,
                    create_repository: value,
                  }))
                }}
              />
            </div>
          </div>

          {/* Content */}
          {featureToggles?.create_repository && (
            <>
              {!Boolean(scm_configuration?.is_repo_linked) ? (
                <>
                  <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                    <FormRow
                      label={
                        <FormLabel htmlFor="org_id">
                          GitHub organization
                        </FormLabel>
                      }
                    >
                      {!isFetchingSourceControlOrgs &&
                        successFetchingSourceControlOrgs && (
                          <>
                            {sourceControlOrgOptions?.length > 0 ? (
                              <div className="w-full inline-flex items-center">
                                <SelectDropdown
                                  values={sourceControlOrgOptions}
                                  selectedValue={
                                    sourceControlConfiguration.scm_organization_id
                                  }
                                  onChange={(value) =>
                                    setSourceControlConfiguration((c) => ({
                                      ...c,
                                      scm_organization_id: value,
                                    }))
                                  }
                                />
                                {!Boolean(deployed_runs_exists) && (
                                  <SecondaryButton
                                    className="ml-2"
                                    onClick={refetchSourceControlOrgs} // TODO: Make sure the toggle stays ON
                                  >
                                    <RefreshIcon
                                      className="-ml-1 mr-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    Refresh
                                  </SecondaryButton>
                                )}
                                <a
                                  href={formatUrl(
                                    ROUTES.ORGANIZATION_SETTINGS.ACCESS_GITHUB,
                                    organizationSlug
                                  )}
                                  className="text-blue-500 hover:text-blue-500 text-sm font-medium ml-4"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Manage
                                </a>
                              </div>
                            ) : (
                              <div className="inline-flex">
                                <Link
                                  target={'_blank'}
                                  to={formatUrl(
                                    ROUTES.ORGANIZATION_SETTINGS.ACCESS_GITHUB,
                                    organizationSlug
                                  )}
                                >
                                  <PrimaryButton onClick={() => { }}>
                                    <PlusIcon
                                      className="-ml-1 mr-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    Create
                                  </PrimaryButton>
                                </Link>
                                <SecondaryButton
                                  className="ml-2"
                                  onClick={refetchSourceControlOrgs} // TODO: Make sure the toggle stays ON
                                >
                                  <RefreshIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  Refresh
                                </SecondaryButton>
                              </div>
                            )}
                          </>
                        )}
                    </FormRow>
                  </div>

                  <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                    <FormRow
                      label={
                        <FormLabel htmlFor="repo_name">
                          GitHub repository name
                        </FormLabel>
                      }
                    >
                      <Input
                        type="text"
                        name="repo_name"
                        id="repo_name"
                        className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        value={sourceControlConfiguration.repo_name}
                        onChange={(e) => {
                          setSourceControlConfiguration((c) => ({
                            ...c,
                            repo_name: e.target.value,
                          }))
                        }}
                      />
                    </FormRow>
                  </div>
                </>
              ) : (
                <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                  <FormRow
                    label={
                      <FormLabel htmlFor="repo_name">
                        GitHub repository
                      </FormLabel>
                    }
                  >
                    <div className="mt-2 inline-flex items-center">
                      <img
                        className="mr-1.5 w-5 h-5"
                        src={githubLogo}
                        alt="GitHub"
                      />
                      <a
                        href={formatUrl(
                          ROUTES.EXTERNAL.GITHUB_REPO,
                          scm_configuration?.scm_organization?.name,
                          scm_configuration?.repo_name
                        )}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:text-blue-500 hover:cursor-pointer"
                      >
                        {scm_configuration.scm_organization.name}/
                        {scm_configuration.repo_name}
                      </a>
                    </div>
                    {/* <PrimaryButton
                          color={COLOR.RED}
                          size={BUTTON_SIZE.SMALL}
                          className="ml-2"
                        >
                          Unlink
                        </PrimaryButton> */}
                  </FormRow>
                </div>
              )}

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="framework">
                      Pre-load Framework
                    </FormLabel>
                  }
                >
                  <SelectDropdown
                    values={frameworkOptions}
                    selectedValue={sourceControlConfiguration.framework_id}
                    disabled={Boolean(scm_configuration?.is_repo_linked)}
                    onChange={(value) =>
                      setSourceControlConfiguration((r) => ({
                        ...r,
                        framework_id: currentStack.frameworks.find(
                          ({ id }) => id === Number.parseInt(value)
                        )?.id,
                      }))
                    }
                  />
                </FormRow>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Domain */}
      <div className="bg-white shadow sm:rounded-md mb-2">
        <div className="py-6 px-4 space-y-6 sm:p-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Domain
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Create a Route 53 record to use a custom domain name for your
                Application Load Balancer
              </p>
            </div>
            <div className="flex items-center">
              <span
                className={classNames(
                  'mr-2 text-sm font-medium',
                  featureToggles?.create_domain_record
                    ? 'text-blue-600'
                    : 'text-gray-400'
                )}
              >
                {/* TODO: Add label for toggle */}
                {featureToggles?.create_domain_record ? 'Enabled' : 'Disabled'}
              </span>
              <Toggle
                enabled={featureToggles?.create_domain_record}
                onChange={(value) => {
                  setFeatureToggles((f) => ({
                    ...f,
                    create_domain_record: value,
                  }))
                }}
              />
            </div>
          </div>

          {/* Content */}
          {featureToggles?.create_domain_record && (
            <>
              <div className="w-full">
                <Alert
                  title="Domain Ownership"
                  subtitle="You must ensure that you own the domain/subdomain name specified below, and that the domain nameservers are configured on your AWS account."
                />
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="aws_hosted_zone">
                      AWS Hosted Zone name
                    </FormLabel>
                  }
                >
                  <Input
                    name="aws_hosted_zone"
                    id="aws_hosted_zone"
                    placeholder="hostedzone.com"
                    value={domainConfiguration.aws_hosted_zone}
                    onChange={(e) => {
                      setDomainConfiguration((d) => ({
                        ...d,
                        aws_hosted_zone: e.target.value,
                      }))
                    }}
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="domain_name">Domain Name</FormLabel>
                  }
                >
                  <Grid>
                    <Col className="inline-flex">
                      <span
                        className={classNames(
                          'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 sm:text-sm',
                          domainConfiguration?.create_ssl
                            ? 'text-green-600'
                            : 'text-gray-500'
                        )}
                      >
                        {domainConfiguration?.create_ssl ? (
                          <>
                            <LockClosedIcon className="h-4 w-4 mr-1 text-green-600" />
                            https://
                          </>
                        ) : (
                          'http://'
                        )}
                      </span>
                      <span className="grow">
                        <Input
                          type="text"
                          name="domain_name"
                          id="domain_name"
                          autoComplete="domain_name"
                          className="rounded-none rounded-r-md border-gray-300"
                          placeholder="api.hostedzone.com"
                          value={domainConfiguration.domain_name}
                          onChange={(e) => {
                            setDomainConfiguration((d) => ({
                              ...d,
                              domain_name: e.target.value,
                            }))
                          }}
                        />
                      </span>
                    </Col>

                    <Col className="flex items-start">
                      <div className="flex items-center h-5">
                        <Input
                          id="create_ssl"
                          aria-describedby="create_ssl"
                          name="create_ssl"
                          type="checkbox"
                          checked={domainConfiguration.create_ssl}
                          onChange={(e) => {
                            setDomainConfiguration((d) => ({
                              ...d,
                              create_ssl: Boolean(!d.create_ssl),
                            }))
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <FormLabel htmlFor="create_ssl" noPad>
                          Add AWS self-signed SSL certificate
                        </FormLabel>
                      </div>
                    </Col>

                    <Col className="flex items-start">
                      <div className="flex items-center h-5">
                        <Input
                          id="create_www_record"
                          aria-describedby="create_www_record"
                          name="create_www_record"
                          type="checkbox"
                          checked={domainConfiguration.create_www_record}
                          onChange={(e) => {
                            setDomainConfiguration((d) => ({
                              ...d,
                              create_www_record: Boolean(!d.create_www_record),
                            }))
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <FormLabel htmlFor="create_www_record" noPad>
                          Create a{' '}
                          <span className="font-mono font-bold text-blue-600">
                            www.
                          </span>{' '}
                          alias record in Route 53
                        </FormLabel>
                      </div>
                    </Col>
                  </Grid>
                </FormRow>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Database */}
      <div className="bg-white shadow sm:rounded-md mb-2">
        <div className="py-6 px-4 space-y-6 sm:p-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Database
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Create an associated Database using AWS RDS
              </p>
            </div>
            <div className="flex items-center">
              <span
                className={classNames(
                  'mr-2 text-sm font-medium',
                  featureToggles?.create_database
                    ? 'text-blue-600'
                    : 'text-gray-400'
                )}
              >
                {featureToggles?.create_database ? 'Enabled' : 'Disabled'}
              </span>
              <Toggle
                enabled={featureToggles?.create_database}
                onChange={(value) => {
                  setFeatureToggles((f) => ({
                    ...f,
                    create_database: value,
                  }))
                }}
              />
            </div>
          </div>

          {/* Content */}
          {featureToggles?.create_database && (
            <>
              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  contentSpan={3}
                  label={<FormLabel>Database engine</FormLabel>}
                >
                  <Grid>
                    <Col span={2}>
                      <FormLabel noPad>Engine name</FormLabel>
                      <div className="mt-1">
                        <SelectDropdown
                          values={[
                            {
                              id: 1,
                              value: 'mysql',
                              name: 'MySQL',
                            },
                          ]}
                          selectedValue={databaseConfiguration?.engine}
                          onChange={(value) =>
                            setDatabaseConfiguration((d) => ({
                              ...d,
                              engine: value,
                            }))
                          }
                        />
                      </div>
                    </Col>
                    <Col span={2}>
                      <FormLabel noPad>Engine version</FormLabel>
                      <div className="mt-1">
                        <SelectDropdown
                          values={[
                            {
                              id: 1,
                              value: '8.0',
                            },
                          ]}
                          selectedValue={databaseConfiguration?.engine_version}
                          onChange={(value) =>
                            setDatabaseConfiguration((d) => ({
                              ...d,
                              engine_version: value,
                            }))
                          }
                        />
                      </div>
                    </Col>
                  </Grid>
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="instance_type">
                      Database instance type
                    </FormLabel>
                  }
                >
                  <SelectDropdown
                    name="instance_type"
                    values={[
                      {
                        id: 1,
                        value: 'db.t3.micro',
                      },
                    ]}
                    selectedValue={databaseConfiguration?.instance_type}
                    onChange={(value) =>
                      setDatabaseConfiguration((d) => ({
                        ...d,
                        instance_type: value,
                      }))
                    }
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={<FormLabel htmlFor="db_name">Database name</FormLabel>}
                >
                  <Input
                    type="text"
                    name="db_name"
                    id="db_name"
                    className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                    value={databaseConfiguration.db_name}
                    onChange={(e) => {
                      setDatabaseConfiguration((d) => ({
                        ...d,
                        db_name: e.target.value,
                      }))
                    }}
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="db_username">
                      Database username
                    </FormLabel>
                  }
                >
                  <Input
                    type="text"
                    name="db_username"
                    id="db_username"
                    className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                    value={databaseConfiguration.db_username}
                    onChange={(e) => {
                      setDatabaseConfiguration((d) => ({
                        ...d,
                        db_username: e.target.value,
                      }))
                    }}
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  contentSpan={3}
                  label={<FormLabel>Database password</FormLabel>}
                >
                  <Alert subtitle="Database password is automatically generated and stored in your AWS Secrets Manager service." />
                </FormRow>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-md">
        <div className="flex items-center justify-end px-4 py-3 text-right sm:px-6">
          <div className='text-center'>
            <SecondaryButton
              onClick={goToPrevious}
              isLoading={isLoading === BUTTON_STYLE.SECONDARY}
              disabled={isLoading !== BUTTON_STYLE.NONE}
            >
              Previous
            </SecondaryButton>
            <PrimaryButton
              className="ml-2"
              onClick={goToNext}
              isLoading={isLoading === BUTTON_STYLE.PRIMARY}
              disabled={isLoading !== BUTTON_STYLE.NONE}
            >
              Preview
            </PrimaryButton>
            <div className='mt-1 text-xs text-gray-500'>Your changes will be saved</div>
          </div>
        </div>
      </div>
    </form>
  )
}
