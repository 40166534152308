import Singleton from './Singleton'
import HttpService from './HttpService'
import IProject from '../interfaces/IProject'
import IHttpResponse from '../interfaces/IHttpResponse'

export default class ProjectService extends Singleton {
  private constructor() {
    super()
  }

  async listProjects(orgId: number) {
    const httpService = HttpService.get()

    // TODO: Validate

    const projectsResponse = await httpService.getProjects(orgId)

    // TODO: Process Response

    return projectsResponse?.data
  }

  async createProject(project: IProject) {
    const httpService = HttpService.get()

    const createResponse = await httpService.createProject(project)

    // TODO: Process Response

    return createResponse?.data
  }

  async updateProject(project: IProject) {
    const httpService = HttpService.get()

    const updateResponse = await httpService.updateProject(project)

    // TODO: Process Response

    return updateResponse?.data
  }

  async getProject(
    organizationSlug: string,
    projectSlug: string
  ): Promise<IProject> {
    const httpService = HttpService.get()

    const getResponse = await httpService.getProject(
      organizationSlug,
      projectSlug
    )

    // TODO: Process Response

    return getResponse?.data
  }

  // TODO: Set up structure for the response model
  async deploy(projectId: number): Promise<boolean> {
    const httpService = HttpService.get()

    const deployResponse = await httpService.deployProject(projectId)

    // TODO: Process Response

    return deployResponse?.data
  }

  createCheckoutSession = async (projectId: number): Promise<IHttpResponse> =>
    HttpService.get().createCheckoutSession(projectId)

  archive = async (projectId: number): Promise<IHttpResponse> =>
    HttpService.get().archiveProject(projectId)
}
