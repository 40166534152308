import AWSRegionEnum from '../enums/AWSRegionEnum'
import FrameworkEnum from '../enums/FrameworkEnum'
import MemberRoleEnum from '../enums/MemberRoleEnum'
import ProjectConfigureStepEnum from '../enums/ProjectConfigureStepEnum'
import ProjectStackEnum from '../enums/ProjectStackEnum'

export const ROUTES = {
  // Main Routes
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  LOGOUT: '/logout',
  ORGANIZATIONS: '/organizations',
  PROJECTS: '/projects',

  // Organizations
  ORGANIZATION_DETAILS: {
    GENERAL: '/organizations/:organizationSlug',
    PROJECTS: '/organizations/:organizationSlug/projects',
  },

  // Organization Settings
  ORGANIZATION_SETTINGS: {
    GENERAL: '/organizations/:organizationSlug/settings',
    USERS: '/organizations/:organizationSlug/settings/users',
    ACCESS_GITHUB: '/organizations/:organizationSlug/settings/github',
    ACCESS_AWS: '/organizations/:organizationSlug/settings/aws',
    PAYMENT_METHODS:
      '/organizations/:organizationSlug/settings/payment-methods',
  },

  // User Settings
  USER_SETTINGS: {
    GENERAL: '/profile/settings',
    CHANGE_PASSWORD: '/profile/settings/change-password',
  },

  // Projects
  PROJECT_DETAILS: '/:organizationSlug/:projectSlug',
  PROJECT_CONFIGURE: {
    BASE: '/:organizationSlug/:projectSlug/configure',
    GENERAL: '/:organizationSlug/:projectSlug/configure/general',
    CONFIGURATION: '/:organizationSlug/:projectSlug/configure/configuration',
    ADD_ONS: '/:organizationSlug/:projectSlug/configure/add-ons',
  },
  PROJECT_PREVIEW: '/:organizationSlug/:projectSlug/preview',

  // Legal
  PRIVACY: '/privacy',
  TERMS: '/terms',

  // Callbacks
  GITHUB_CALLBACK: '/github-callback',

  // External
  EXTERNAL: {
    GITHUB_ORG: 'https://github.com/:orgName',
    GITHUB_ORG_PRETTY: 'github.com/:orgName',
    GITHUB_REPO: 'https://github.com/:orgName/:repoName',
  },
}

export const API_ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  USER_ORGS: '/organizations',
  LIST_PROJECTS: '/organization/:id/projects',
  CREATE_PROJECT: '/project/create',
  UPDATE_PROJECT: '/project/update/:id',
  PROJECT_DETAILS: '/project/:organizationSlug/:projectSlug',
  DEPLOY_PROJECT: '/project/deploy/:projectId',
  ARCHIVE_PROJECT: '/project/archive/:projectId',
  ORGANIZATION_DETAILS: '/organization/:organizationSlug',
  CREATE_ORGANIZATION: '/organization/create',

  // TODO: Migrate to this structure for all credential API operations
  ORGANIZATION: {
    CREDENTIALS: {
      CLOUD: {
        LIST: '/organization/:organizationId/credentials/cloud',
      },
      SCM: {
        LIST: '/organization/:organizationId/credentials/scm',
        CREATE: '/organization/:organizationId/credentials/scm/create',
        LIST_ORGANIZATIONS:
          '/organization/:organizationId/credentials/scm/organizations',
        UPDATE_ORGANIZATIONS:
          '/organization/:organizationId/credentials/scm/:scmCredentialsId/organizations/update',
      },
    },
  },

  PROJECT: {
    PAYMENT: {
      CREATE_CHECKOUT_SESSION:
        '/project/payment/create-checkout-session/:projectId',
    },
  },

  CREATE_CLOUD_CREDENTIALS:
    '/organization/:organizationId/credentials/cloud/create', // TODO: Move this inside ORGANIZATION and rename it

  USER_DETAILS: '/user',
  DASHBOARD: '/dashboard',
  CHANGE_PASSWORD: '/change-password',
  METADATA: '/metadata',
}

export const ROUTE_ENUM_MAP = {
  [ProjectConfigureStepEnum.General]: ROUTES.PROJECT_CONFIGURE.GENERAL,
  [ProjectConfigureStepEnum.Configuration]:
    ROUTES.PROJECT_CONFIGURE.CONFIGURATION,
  [ProjectConfigureStepEnum.AddOns]: ROUTES.PROJECT_CONFIGURE.ADD_ONS,
}

export const PUBLIC_ROUTES = [ROUTES.LOGIN]

export const HTTP_STATUS = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
}

export const BUTTON_STYLE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OTHER: 'other',
  NONE: 'none',
}

export const BUTTON_VARIANT = {
  WIDE: 'wide',
}

export const BADGE_TYPE = {
  DRAFT: 'Draft',
  DEPLOYING: 'Deploying',
  DEPLOYED: 'Deployed',
  MODIFIED: 'Modified',
  FAILED: 'Failed',
}

export const STACK_TEXT = {
  [ProjectStackEnum.ECS]: 'AWS ECS',
  [ProjectStackEnum.EKS]: 'AWS EKS',
}

export const TASK_CPU_LIST = {
  256: '.25 vCPU',
  512: '.5 vCPU',
  1024: '1 vCPU',
}

export const TASK_MEMORY_LIST = {
  256: [512, 1024, 2048],
  512: [1024, 2048, 3072, 4096],
  1024: [2048, 3072, 4096, 5120, 6144, 7168, 8192],
}

export const TASK_MEMORY_NAMES = {
  512: '512 MB',
  1024: '1 GB',
  2048: '2 GB',
  3072: '3 GB',
  4096: '4 GB',
  5120: '5 GB',
  6144: '6 GB',
  7168: '7 GB',
  8192: '8 GB',
}

export const DB_ENGINE_NAMES = {
  mysql: 'MySQL',
}

export const AWS_REGIONS = [
  { id: 1, value: AWSRegionEnum.USEast1 },
  { id: 2, value: AWSRegionEnum.USEast2 },
  { id: 3, value: AWSRegionEnum.USWest2 },
  { id: 4, value: AWSRegionEnum.CACentral1 },
]

export const TASK_SIZE_VARIANT = {
  CPU: 'cpu',
  MEMORY: 'memory',
}

export const FRAMEWORK_DISPLAY_NAME = {
  [FrameworkEnum.Laravel]: 'Laravel',
}

export const COLOR = {
  GRAY: 'gray',
  PINK: 'pink',
  PURPLE: 'purple',
  BLUE: 'blue',
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  WHITE: 'white',
  ORANGE: 'orange',
}

export const MODAL_TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  NONE: 'none',
}

export const BUTTON_SIZE = {
  SMALL: 'small',
  REGULAR: 'regular',
  BIG: 'big',
}

export const BREAKPOINT_SIZE = {
  SMALL: 'sm',
  LARGET: 'lg',
}

export const ROLE_NAME = {
  [MemberRoleEnum.Admin]: 'Admin',
  [MemberRoleEnum.Manager]: 'Manager',
  [MemberRoleEnum.ReadOnly]: 'Read Only',
}

// TODO: Migrate other hooks here
export const HOOK_KEY = {
  ORGANIZATIONS: 'organizations',
  PROJECT: 'project',
  PROJECTS: 'projects',
  USER: 'user',
}

const defaultExport = {}
export default defaultExport
