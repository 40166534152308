import axios from 'axios'
import AuthenticationService from '../services/AuthenticationService'
import { env } from './functions'

export default function axiosMiddleware(): void {
  const authenticationService = AuthenticationService.get()
  axios.defaults.baseURL = env().API_URL

  // Request Interceptors
  axios.interceptors.request.use(
    authenticationService.axiosRequestSuccessHandler,
    authenticationService.axiosRequestErrorHandler
  )

  // Response Interceptors
  axios.interceptors.response.use(
    authenticationService.axiosResponseSuccessHandler,
    authenticationService.axiosResponseErrorHandler
  )
}
