export const defaultUserContext = {
  token: null,
  selectedOrg: null,
}

export const notificationPropsDefault = {
  open: false,
  type: null,
  title: null,
  subtitle: null,
}

const defaultExport = {}
export default defaultExport
