import { useQuery } from 'react-query'
import OrganizationService from '../services/OrganizationService'

export default function useListSCMOrganizations(orgId) {
  const organizationService = OrganizationService.get()

  return useQuery(
    ['scm_organizations', orgId],
    () => organizationService.getScmOrganizations(orgId),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(orgId),
    }
  )
}
