import { useQuery } from 'react-query'
import OrganizationService from '../services/OrganizationService'

export default function useGetOrganization(organizationSlug) {
  const organizationService = OrganizationService.get()

  return useQuery(
    ['organization', organizationSlug],
    () => organizationService.getOrganization(organizationSlug),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(organizationSlug),
    }
  )
}
