import { useEffect, useState, useCallback, useContext } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import useGetProject from '../../hooks/useGetProject'
import { ROUTES } from '../../helpers/constants.ts'
import Layout from '../Layout'
import { formatUrl, isRoute } from '../../helpers/functions'
import ProjectConfigureContext from '../../context/ProjectConfigureContext'
import ProjectConfigureStepEnum from '../../enums/ProjectConfigureStepEnum'
import ProjectConfigureSteps from './ProjectConfigureSteps'
import General from './Steps/General'
import Configuration from './Steps/Configuration'
import AddOns from './Steps/AddOns'
import ProjectService from '../../services/ProjectService'
import { COLOR, ROUTE_ENUM_MAP } from '../../helpers/constants'
import { PrimaryButton } from '../Form/Button'
import { EyeIcon, FireIcon } from '@heroicons/react/solid'
import NotificationContext from '../../context/NotificationContext'
import NotificationTypeEnum from '../../enums/NotificationTypeEnum'
import locale from '../../translations/en'

export default function ProjectConfigureContainer() {
  const projectService = ProjectService.get()
  const { organizationSlug, projectSlug } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [pageTitle, setPageTitle] = useState(null)
  const [project, setProject] = useState(null)
  const { isLoading, data: projectData } = useGetProject(
    organizationSlug,
    projectSlug,
    null,
    location.pathname
  )
  const [currentStep, setCurrentStep] = useState(null)

  const breadcrumbs = project && [
    {
      name: project.organization?.name,
      path: formatUrl(
        ROUTES.ORGANIZATION_DETAILS.GENERAL,
        project.organization?.slug
      ),
    },
    {
      name: 'Projects',
      // TODO: Re-enable when Org->Projects section is completed
      // path: formatUrl(
      //   ROUTES.ORGANIZATION_DETAILS.PROJECTS,
      //   project.organization?.slug
      // ),
    },
    {
      name: project.name,
      path: formatUrl(
        ROUTES.PROJECT_DETAILS,
        project.organization?.slug,
        project.slug
      ),
    },
    {
      name: 'Configure',
    },
  ]

  useEffect(() => {
    let step = null
    if (isRoute(ROUTES.PROJECT_CONFIGURE.GENERAL, location.pathname)) {
      step = ProjectConfigureStepEnum.General
    } else if (
      isRoute(ROUTES.PROJECT_CONFIGURE.CONFIGURATION, location.pathname)
    ) {
      step = ProjectConfigureStepEnum.Configuration
    } else if (isRoute(ROUTES.PROJECT_CONFIGURE.ADD_ONS, location.pathname)) {
      step = ProjectConfigureStepEnum.AddOns
    } else {
      // TODO: redirect to summary page
      // organizationSlug, projectSlug
      console.error('Invalid URL')
      return
    }

    setCurrentStep(step)
  }, [location])

  // Sets page title
  // Auto-redirect to the Configure page
  // Sets default `project` value
  useEffect(() => {
    if (isLoading || !projectData) {
      return
    }

    setPageTitle(projectData.name)
    setProject(projectData)
  }, [projectData, isLoading])

  const onStepChange = useCallback(
    async (step, updatedProjectDelta) => {
      const updatedProject = Boolean(updatedProjectDelta)
        ? {
          ...project,
          ...updatedProjectDelta,
          organization: null,
          cloud_credentials: null,
          stack: null,
          latest_run: null,
          metadata: null,
        }
        : project
      const updateProjectResponse = await projectService
        .updateProject(updatedProject)
        .catch((e) => {
          showNotification(
            NotificationTypeEnum.Error,
            locale.project.errorUpdate,
            e?.response?.data?.message,
            5000
          )
        })

      if (
        updateProjectResponse?.success &&
        updateProjectResponse?.data?.project
      ) {
        setProject(updateProjectResponse.data.project)

        if (step !== null) {
          navigate(
            formatUrl(ROUTE_ENUM_MAP[step], organizationSlug, projectSlug)
          )
        }
      }

      return updateProjectResponse?.success
    },
    [project, navigate, projectService, organizationSlug, projectSlug]
  )

  const ProjectConfigureComponent = () => {
    const commonProps = {
      currentStep,
      onStepChange,
    }

    switch (currentStep) {
      case ProjectConfigureStepEnum.General:
        return <General {...commonProps} />
      case ProjectConfigureStepEnum.Configuration:
        return <Configuration {...commonProps} />
      case ProjectConfigureStepEnum.AddOns:
        return <AddOns {...commonProps} />
      default:
        return null
    }
  }

  return (
    <Layout
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      primaryButton={
        <Link
          to={formatUrl(ROUTES.PROJECT_PREVIEW, organizationSlug, projectSlug)}
        >
          {/* TODO: Disable if task configuration is not completed */}
          <PrimaryButton className="mr-2">
            <EyeIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Preview
          </PrimaryButton>
        </Link>
      }
    // secondaryButton={
    //   <PrimaryButton color={COLOR.ORANGE} className="mr-2">
    //     <FireIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
    //     Destroy
    //   </PrimaryButton>
    // }
    >
      {!isLoading && project && (
        <ProjectConfigureContext.Provider value={{ project, setProject }}>
          <ProjectConfigureSteps
            currentStep={currentStep}
            onStepChange={onStepChange}
          />
          <ProjectConfigureComponent />
        </ProjectConfigureContext.Provider>
      )}
    </Layout>
  )
}
