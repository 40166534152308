import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ExclamationIcon,
  CheckIcon,
  InformationCircleIcon,
  XIcon,
  BanIcon,
} from '@heroicons/react/outline'
import { PrimaryButton, SecondaryButton } from './Form/Button'
import { BUTTON_STYLE, COLOR, MODAL_TYPE } from '../helpers/constants'
import { classNames } from '../helpers/functions'

export default function Modal({
  isOpen,
  onClick,
  onClose,
  title,
  text,
  children,
  showClose = true,
  type = MODAL_TYPE.NONE,
  primaryButtonText = 'Submit',
  primaryButtonDisabled = false,
  secondaryButtonText = 'Cancel',
}) {
  const [isLoading, setIsLoading] = useState(BUTTON_STYLE.NONE)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  const onSubmit = async () => {
    const isSubmitAsync = onClick?.constructor?.name === 'AsyncFunction'

    if (isSubmitAsync) {
      setIsLoading(BUTTON_STYLE.PRIMARY)
      await onClick()

      if (isMounted.current) {
        setIsLoading(BUTTON_STYLE.NONE)
      }
    } else {
      onClick()
    }
  }

  // TODO: useMemo
  const getModalColor = () => {
    switch (type) {
      case MODAL_TYPE.INFO:
        return COLOR.BLUE
      case MODAL_TYPE.SUCCESS:
        return COLOR.GREEN
      case MODAL_TYPE.WARNING:
        return COLOR.YELLOW
      case MODAL_TYPE.ERROR:
        return COLOR.RED
      default:
        return null
    }
  }

  const ModalIcon = ({ color }) => {
    const sharedProps = {
      className: classNames('h-6 w-6', `text-${color}-600`),
      'aria-hidden': 'true',
    }

    switch (type) {
      case MODAL_TYPE.INFO:
        return <InformationCircleIcon {...sharedProps} />
      case MODAL_TYPE.SUCCESS:
        return <CheckIcon {...sharedProps} />
      case MODAL_TYPE.WARNING:
        return <ExclamationIcon {...sharedProps} />
      case MODAL_TYPE.ERROR:
        return <BanIcon {...sharedProps} />
      default:
        return null
    }
  }

  const modalColor = getModalColor()

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  {showClose && (
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  )}
                </div>
                <div className="sm:flex sm:items-start">
                  {modalColor && (
                    <div
                      className={classNames(
                        'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
                        `bg-${modalColor}-100`
                      )}
                    >
                      <ModalIcon color={modalColor} />
                    </div>
                  )}
                  <div
                    className={classNames(
                      'mt-3 text-center sm:mt-0 sm:text-left w-full',
                      type !== MODAL_TYPE.NONE && 'sm:ml-4'
                    )}
                  >
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    <div className={classNames(title && 'mt-2')}>
                      {Boolean(text) && (
                        <p className="text-sm text-gray-500">{text}</p>
                      )}
                      {Boolean(children) && children}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <PrimaryButton
                    className="ml-2"
                    onClick={onSubmit}
                    disabled={
                      isLoading !== BUTTON_STYLE.NONE || primaryButtonDisabled
                    }
                    isLoading={isLoading === BUTTON_STYLE.PRIMARY}
                  >
                    {primaryButtonText}
                  </PrimaryButton>
                  <SecondaryButton
                    onClick={onClose}
                    disabled={isLoading !== BUTTON_STYLE.NONE}
                    isLoading={isLoading === BUTTON_STYLE.SECONDARY}
                  >
                    {secondaryButtonText}
                  </SecondaryButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
