import { Routes as DomRoutes, Route } from 'react-router-dom'
import { ROUTES } from './helpers/constants.ts'
import Home from './pages/Home'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Logout from './pages/Logout'
import Organizations from './pages/Organizations'
import Projects from './pages/Projects'
import ProjectPreviewContainer from './components/ProjectPreview/ProjectPreviewContainer'
import OrganizationDetails from './pages/OrganizationDetails'
import GitHubCallback from './components/callbacks/GitHubCallback'
import ProjectDetailsContainer from './components/ProjectDetails/ProjectDetailsContainer'
import ProjectConfigureContainer from './components/ProjectConfigure/ProjectConfigureContainer'
import OrganizationSettingsGeneral from './components/Organizations/Settings/OrganizationSettingsGeneral'
import OrganizationSettingsAccessGithub from './components/Organizations/Settings/OrganizationSettingsAccessGithub'
import OrganizationSettingsAccessAWS from './components/Organizations/Settings/OrganizationSettingsAccessAWS'
import OrganizationSettingsUsers from './components/Organizations/Settings/OrganizationSettingsUsers'
import OrganizationSettingsPaymentMethods from './components/Organizations/Settings/OrganizationSettingsPaymentMethods'
import UserSettingsGeneral from './components/User/Settings/UserSettingsGeneral'
import UserSettingsChangePassword from './components/User/Settings/UserSettingsChangePassword'
import Terms from './components/legal/Terms.js'
import Privacy from './components/legal/Privacy.js'

export default function Routes() {
  return (
    <DomRoutes>
      {/* Main Routes */}
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.LOGOUT} element={<Logout />} />
      <Route path={ROUTES.ORGANIZATIONS} element={<Organizations />} />
      <Route path={ROUTES.PROJECTS} element={<Projects />} />

      {/* Organizations */}
      <Route
        path={ROUTES.ORGANIZATION_DETAILS.GENERAL}
        element={<OrganizationDetails />}
      />

      {/* Organization Settings */}
      <Route
        path={ROUTES.ORGANIZATION_SETTINGS.GENERAL}
        element={<OrganizationSettingsGeneral />}
      />
      <Route
        path={ROUTES.ORGANIZATION_SETTINGS.USERS}
        element={<OrganizationSettingsUsers />}
      />
      <Route
        path={ROUTES.ORGANIZATION_SETTINGS.ACCESS_GITHUB}
        element={<OrganizationSettingsAccessGithub />}
      />
      <Route
        path={ROUTES.ORGANIZATION_SETTINGS.ACCESS_AWS}
        element={<OrganizationSettingsAccessAWS />}
      />
      <Route
        path={ROUTES.ORGANIZATION_SETTINGS.PAYMENT_METHODS}
        element={<OrganizationSettingsPaymentMethods />}
      />

      {/* Projects */}
      <Route
        path={ROUTES.PROJECT_DETAILS}
        element={<ProjectDetailsContainer />}
      />
      <Route
        path={ROUTES.PROJECT_PREVIEW}
        element={<ProjectPreviewContainer />}
      />
      {[
        ROUTES.PROJECT_CONFIGURE.BASE,
        ROUTES.PROJECT_CONFIGURE.GENERAL,
        ROUTES.PROJECT_CONFIGURE.CONFIGURATION,
        ROUTES.PROJECT_CONFIGURE.ADD_ONS,
      ].map((path, index) => (
        <Route
          path={path}
          key={index}
          element={<ProjectConfigureContainer />}
        />
      ))}

      {/* User Settings */}
      <Route
        path={ROUTES.USER_SETTINGS.GENERAL}
        element={<UserSettingsGeneral />}
      />
      <Route
        path={ROUTES.USER_SETTINGS.CHANGE_PASSWORD}
        element={<UserSettingsChangePassword />}
      />

      {/* Callbacks */}
      <Route path={ROUTES.GITHUB_CALLBACK} element={<GitHubCallback />} />

      {/* Legal */}
      <Route path={ROUTES.TERMS} element={<Terms />} />
      <Route path={ROUTES.PRIVACY} element={<Privacy />} />
    </DomRoutes>
  )
}
