import { useQuery } from 'react-query'
import { HOOK_KEY } from '../helpers/constants'
import OrganizationService from '../services/OrganizationService'

export default function useListOrganizations(enabled = true) {
  const organizationService = OrganizationService.get()

  return useQuery(
    HOOK_KEY.ORGANIZATIONS,
    () => organizationService.getUserOrgs(),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  )
}
