export default function Heading({ size = 1, children }) {
  const SizedHeading = (props) => {
    switch (size) {
      case 1:
        return <h1 {...props} />
      case 2:
        return <h2 {...props} />
      case 3:
        return <h3 {...props} />
      case 4:
        return <h4 {...props} />
      case 5:
        return <h5 {...props} />
      case 6:
        return <h6 {...props} />
    }
  }

  return (
    <SizedHeading className="text-lg leading-6 font-medium text-gray-900">
      {children}
    </SizedHeading>
  )
}
