import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function GitHubCallback() {
  const { search } = useLocation()
  const [code, setCode] = useState(null)

  useEffect(() => {
    if (!search) {
      return
    }

    const searchParams = new URLSearchParams(search)
    setCode(searchParams.get('code'))
  }, [search])

  return code
}
