/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { classNames } from '../helpers/functions'
import { ROUTES } from '../helpers/constants.ts'
import OrganizationSelector from './Organizations/OrganizationSelector'
import webdnaLogo from '../images/webdna-logo.svg'
import webdnaLogoIcon from '../images/webdna-logo-icon.svg'
import OrganizationService from '../services/OrganizationService'
import useGetUser from '../hooks/useGetUser'

export default function NavBar() {
  const organizationService = OrganizationService.get()
  const [projectsLinkVisible, setProjectsLinkVisible] = useState(true)
  const [routes, setRoutes] = useState([
    {
      path: ROUTES.HOME,
      label: 'Dashboard',
      visible: true,
    },
    {
      path: ROUTES.ORGANIZATIONS,
      label: 'Organizations',
      visible: true,
    },
    {
      path: ROUTES.PROJECTS,
      label: 'Projects',
      visible: projectsLinkVisible,
    },
  ])
  const [currentRoute, setCurrentRoute] = useState(null)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isLoading, data: user } = useGetUser()

  useEffect(() => {
    let currentRoute = pathname

    setCurrentRoute(currentRoute)
  }, [pathname])

  useEffect(() => {
    if (
      Boolean(user?.data) &&
      pathname !== ROUTES.USER_SETTINGS.CHANGE_PASSWORD &&
      user.data.password_updated_at === null
    ) {
      navigate(ROUTES.USER_SETTINGS.CHANGE_PASSWORD)
    }
  }, [user])

  useEffect(() => {
    if (!Boolean(organizationService.getOrgId())) {
      setProjectsLinkVisible(false)
    }
  }, [])

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <Link to={ROUTES.HOME}>
                    <img
                      className="block lg:hidden h-10 w-auto"
                      src={webdnaLogoIcon}
                      alt="WebDNA"
                    />
                  </Link>
                  <Link to={ROUTES.HOME}>
                    <img
                      className="hidden lg:block w-[120px] h-[40px]"
                      src={webdnaLogo}
                      alt="WebDNA"
                    />
                  </Link>
                  <span className="lg:block inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-500 text-white ml-2">
                    BETA
                  </span>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {routes.map(
                    (route) =>
                      route.visible && (
                        <Link
                          to={route.path}
                          className={classNames(
                            currentRoute === route.path
                              ? 'border-blue-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                          )}
                          key={route.label}
                        >
                          {route.label}
                        </Link>
                      )
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <OrganizationSelector />

                <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <span className="sr-only">Open user menu</span>
                        <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-blue-200">
                          <svg
                            className="h-full w-full text-blue-400"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={ROUTES.USER_SETTINGS.GENERAL}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Account Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={ROUTES.LOGOUT}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Sign out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {routes.map((route) => (
                <Disclosure.Button
                  as={Link}
                  to={route.path}
                  key={route.label}
                  className={classNames(
                    currentRoute === route.path
                      ? 'bg-blue-50 border-blue-500 text-blue-700'
                      : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6'
                  )}
                >
                  {route.label}
                </Disclosure.Button>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4 sm:px-6">
                <div className="inline-flex justify-center h-10 w-10 rounded-full overflow-hidden bg-blue-200">
                  <svg
                    className="h-full w-full text-blue-400"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user?.data?.name}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user?.data?.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as={Link}
                  to={ROUTES.USER_SETTINGS.GENERAL}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                >
                  Account Settings
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to={ROUTES.LOGOUT}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
