import { RadioGroup } from '@headlessui/react'
import { classNames } from '../../helpers/functions'
import {
  TASK_CPU_LIST,
  TASK_MEMORY_NAMES,
  TASK_SIZE_VARIANT,
} from '../../helpers/constants'

export default function TaskSizePicker({
  variant,
  values,
  selectedValue,
  onChange,
}) {
  const TaskSizeOptions = () => {
    if (variant === TASK_SIZE_VARIANT.CPU) {
      return (
        <div className="grid gap-2 grid-cols-5 grid-rows-1">
          {values?.map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  'cursor-pointer focus:outline-none',
                  active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                  checked
                    ? 'bg-blue-600 border-transparent text-white hover:bg-blue-700'
                    : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                  'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1'
                )
              }
            >
              <RadioGroup.Label as="span">
                {TASK_CPU_LIST[option]}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      )
    } else {
      return (
        <div className="grid gap-2 grid-cols-5 grid-rows-2">
          {Object.keys(TASK_MEMORY_NAMES).map((option) => {
            const isEnabled = values?.includes(Number.parseInt(option))

            return (
              <RadioGroup.Option
                key={option}
                value={option}
                className={({ active, checked }) =>
                  classNames(
                    isEnabled
                      ? 'cursor-pointer focus:outline-none'
                      : 'opacity-25 cursor-not-allowed',
                    active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                    checked
                      ? 'bg-blue-600 border-transparent text-white hover:bg-blue-700'
                      : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                    'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1'
                  )
                }
                disabled={!isEnabled}
              >
                <RadioGroup.Label as="span">
                  {TASK_MEMORY_NAMES[option]}
                </RadioGroup.Label>
              </RadioGroup.Option>
            )
          })}
        </div>
      )
    }
  }

  return (
    <RadioGroup value={selectedValue} onChange={onChange}>
      <RadioGroup.Label className="sr-only">
        Choose a memory option
      </RadioGroup.Label>
      <TaskSizeOptions />
    </RadioGroup>
  )
}
