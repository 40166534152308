import { Fragment, useEffect, useState, useContext, useCallback } from 'react'
import { useQuery } from 'react-query'
import { Listbox, Transition } from '@headlessui/react'
import {
  CheckIcon,
  SelectorIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/solid'
import UserContext from '../../context/UserContext'
import OrganizationService from '../../services/OrganizationService'
import AuthenticationService from '../../services/AuthenticationService'
import { classNames } from '../../helpers/functions'
import '../../sass/components/OrganizationSelector.scss'
import { ROUTES } from '../../helpers/constants'
import { Link, useLocation } from 'react-router-dom'
import useListOrganizations from '../../hooks/useListOrganizations'

export default function OrganizationSelector() {
  const organizationService = OrganizationService.get()
  const authenticationService = AuthenticationService.get()
  const { pathname } = useLocation()
  const [selected, setSelected] = useState(null)
  const { setUserContext } = useContext(UserContext)
  const isShown = pathname === ROUTES.PROJECTS

  // TODO: Move to useListOrganizations hook
  const {
    isLoading,
    error,
    data: organizations,
  } = useListOrganizations(isShown)

  const setGlobalOrg = useCallback(
    (org) => {
      if (!org) {
        return
      }

      setUserContext((u) => ({ ...u, selectedOrg: org }))
      organizationService.setOrgId(org.id)
    },
    [setUserContext, organizationService]
  )

  const onOrgChange = (org) => {
    if (!org) {
      return
    }

    setGlobalOrg(org)
    setSelected(org)
  }

  useEffect(() => {
    if (isLoading || error || !organizations?.length) {
      return
    }

    // Handle "no orgs" scenario
    let currentOrgId = organizationService.getOrgId()
    let currentOrg
    if (!currentOrgId) {
      currentOrg = organizations[0]
    } else {
      const findOrg = organizations.find(({ id }) => id === currentOrgId)
      if (!findOrg) {
        organizationService.removeOrgId()
        authenticationService.redirectToLogin()
      }

      currentOrg = findOrg
    }

    setGlobalOrg(currentOrg)
    setSelected(currentOrg)
  }, [
    isLoading,
    error,
    organizations,
    organizationService,
    authenticationService,
    setGlobalOrg,
  ])

  if (!isShown || !organizations?.length) {
    return null
  }

  return (
    <Listbox value={selected} onChange={onOrgChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Change Current Organization
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex shadow-sm rounded-md divide-x divide-blue-600">
              <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-blue-600">
                <div className="relative inline-flex items-center bg-white text-sm font-medium text-gray-700 py-2 pl-3 pr-4 border border-gray-300 rounded-l-md shadow-sm">
                  <OfficeBuildingIcon
                    className="h-5 w-5 text-blue-600"
                    aria-hidden="true"
                  />
                  <p className="ml-2 text-sm font-medium wd-short-title">
                    {selected?.name}
                  </p>
                </div>
                <Listbox.Button className="relative inline-flex items-center bg-blue-600 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500">
                  <span className="sr-only">Change Current Organization</span>
                  <SelectorIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Listbox.Option
                  disabled={true}
                  className="bg-gray-50 text-blue-800 cursor-default select-none relative py-2 px-4 text-xs flex justify-between"
                >
                  <p className="font-semibold">Select Current Organization</p>
                  <Link
                    to={ROUTES.ORGANIZATIONS}
                    className="text-blue-700 hover:text-blue-900 hover:underline"
                  >
                    Manage
                  </Link>
                </Listbox.Option>
                {organizations?.map((option) => (
                  <Listbox.Option
                    key={option.name}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-blue-500' : 'text-gray-900',
                        'cursor-default select-none relative p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {option.name}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? 'text-white' : 'text-blue-500'
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
