import { PlusIcon } from '@heroicons/react/outline'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BUTTON_SIZE, COLOR, ROUTES } from '../../../helpers/constants'
import { formatUrl } from '../../../helpers/functions'
import useGetOrganization from '../../../hooks/useGetOrganization'
import useListCloudCredentials from '../../../hooks/useListCloudCredentials'
import { PrimaryButton } from '../../Form/Button'
import FormRow from '../../Form/FormRow'
import Heading from '../../Heading'
import Layout from '../../Layout'
import Card from '../../Layout/Card'
import { Grid, Col } from '../../Layout/Grid'
import CreatePaymentMethodSlideout from './CreatePaymentMethodSlideout'
import OrganizationSettingsNav from './OrganizationSettingsNav'

export default function OrganizationSettingsPaymentMethods() {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const { organizationSlug } = useParams()
  const [organizationId, setOrganizationId] = useState(null)
  const { isLoading, data: organizationDetails } =
    useGetOrganization(organizationSlug)

  const onCreate = () => {
    setIsFormOpen(false)
  }

  useEffect(() => {
    if (!organizationDetails) {
      return
    }

    setOrganizationId(organizationDetails?.id)
  }, [organizationDetails])

  const breadcrumbs = [
    {
      name: organizationDetails?.name,
      path: formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, organizationSlug),
    },
    {
      name: 'Settings',
    },
  ]

  return (
    <Layout
      title={organizationDetails?.name}
      breadcrumbs={breadcrumbs}
      nav={
        <OrganizationSettingsNav
          organizationSlug={organizationSlug}
          currentRoute={ROUTES.ORGANIZATION_SETTINGS.PAYMENT_METHODS}
        />
      }
    >
      <Card
        header={
          <Grid cols={2}>
            <Col span={1} className="flex items-center">
              <div>
                <Heading size={3}>Payment Methods</Heading>
                <p className="text-sm text-gray-500">
                  Set up payment methods for your organization
                </p>
              </div>
            </Col>
            <Col span={1} className="text-right">
              <PrimaryButton
                onClick={() => {
                  setIsFormOpen(true)
                }}
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Add
              </PrimaryButton>
            </Col>
          </Grid>
        }
      >
        <div className="text-sm text-center text-gray-500">
          No payment methods found
        </div>
      </Card>
      {/* <CreatePaymentMethodSlideout
        open={isFormOpen}
        onClose={() => {
          setIsFormOpen(false)
        }}
        organizationId={organizationId}
        onCreate={onCreate}
      /> */}
    </Layout>
  )
}
