import { classNames } from '../../helpers/functions'

export const Grid = ({ cols = 6, gap = 4, className, children }) => {
  return (
    <div
      className={classNames(
        `grid sm:grid-cols-${cols} sm:gap-${gap}`,
        Boolean(className) && className
      )}
    >
      {children}
    </div>
  )
}

// TODO: Export into separate component
export const Col = ({ span = 6, rowKey, className, children }) => (
  <div
    key={rowKey}
    className={classNames(
      `sm:col-span-${span}`,
      Boolean(className) && className
    )}
  >
    {children}
  </div>
)

export default {}
