import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/constants'
import { formatUrl } from '../../../helpers/functions'
import useGetOrganization from '../../../hooks/useGetOrganization'
import Alert from '../../_shared/Alert'
import Heading from '../../Heading'
import Layout from '../../Layout'
import Card from '../../Layout/Card'
import { Grid, Col } from '../../Layout/Grid'
import OrganizationSettingsNav from './OrganizationSettingsNav'

export default function OrganizationSettingsUsers() {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const { organizationSlug } = useParams()
  const [organizationId, setOrganizationId] = useState(null)
  const { isLoading, data: organizationDetails } =
    useGetOrganization(organizationSlug)

  useEffect(() => {
    if (!organizationDetails) {
      return
    }

    setOrganizationId(organizationDetails?.id)
  }, [organizationDetails])

  const breadcrumbs = [
    {
      name: organizationDetails?.name,
      path: formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, organizationSlug),
    },
    {
      name: 'Settings',
    },
  ]

  // || !cloudCredentials
  if (isLoading || !organizationDetails) {
    return null
  }

  return (
    <Layout
      title={organizationDetails.name}
      breadcrumbs={breadcrumbs}
      nav={
        <OrganizationSettingsNav
          organizationSlug={organizationSlug}
          currentRoute={ROUTES.ORGANIZATION_SETTINGS.USERS}
        />
      }
    >
      <Card
        header={
          <Grid cols={2}>
            <Col span={1} className="flex items-center">
              <div>
                <Heading size={3}>Users</Heading>
                <p className="text-sm text-gray-500">
                  Manage Users and Access within the Organization
                </p>
              </div>
            </Col>
          </Grid>
        }
      >
        <div className="text-sm text-center text-gray-500">
          <Alert
            subtitle={
              <span>
                This feature is currently disabled as part of the Private Beta
                program. Please email{' '}
                <a
                  href="mailto:beta-register@webdna.dev"
                  className="font-medium text-yellow-700 underline hover:text-yellow-600"
                >
                  beta-register@webdna.dev
                </a>{' '}
                in order to request an invitation.
              </span>
            }
          />
        </div>
      </Card>
    </Layout>
  )
}
