import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_VARIANT,
  COLOR,
} from '../../helpers/constants'
import { classNames } from '../../helpers/functions'
import LoadingSpinner from '../LoadingSpinner'

const Button = ({
  children,
  style = BUTTON_STYLE.PRIMARY,
  color = COLOR.BLUE,
  size = BUTTON_SIZE.REGULAR,
  variant,
  isLoading,
  disabled,
  type = 'button',
  className,
  onClick,
}) => {
  const buttonStyles = []

  switch (style) {
    case BUTTON_STYLE.PRIMARY:
      buttonStyles.push('border-transparent text-white')
      buttonStyles.push(
        isLoading || disabled
          ? `bg-${color}-500 hover:bg-${color}-500 hover:cursor-not-allowed`
          : `bg-${color}-600 hover:bg-${color}-700` // TODO: Add to TailwindBuildClasses.js
      )
      buttonStyles.push(`focus:ring-${color}-500`)
      break
    case BUTTON_STYLE.SECONDARY:
      buttonStyles.push(
        'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
      )
      break
    default:
      break
  }

  switch (variant) {
    case BUTTON_VARIANT.WIDE:
      buttonStyles.push('w-full')
      break
    default:
      break
  }

  switch (size) {
    case BUTTON_SIZE.SMALL:
      buttonStyles.push('px-2.5 py-1.5 text-xs')
      break
    case BUTTON_SIZE.REGULAR:
      buttonStyles.push('px-4 py-2 text-sm')
      break
    case BUTTON_SIZE.BIG:
      buttonStyles.push('px-4 py-2 text-base')
      break
  }

  return (
    <button
      type={type}
      className={classNames(
        'inline-flex justify-center rounded-md border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
        ...buttonStyles,
        className && className
      )}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {isLoading && (
        <LoadingSpinner
          colorClass={
            style === BUTTON_STYLE.PRIMARY ? 'text-white' : 'text-gray'
          }
        />
      )}
      {children}
    </button>
  )
}

export const PrimaryButton = (props) => (
  <Button style={BUTTON_STYLE.PRIMARY} {...props} />
)

export const SecondaryButton = (props) => (
  <Button style={BUTTON_STYLE.SECONDARY} {...props} />
)

export default Button
