import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import webdnaLogo from '../images/webdna-logo.svg'
import AuthenticationService from '../services/AuthenticationService'
import OrganizationService from '../services/OrganizationService'
import { BUTTON_VARIANT, ROUTES } from '../helpers/constants'
import { PrimaryButton } from '../components/Form/Button'
import NotificationContext from '../context/NotificationContext'
import NotificationTypeEnum from '../enums/NotificationTypeEnum'
import locale from '../translations/en'

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const authenticationService = AuthenticationService.get()
  const organizationService = OrganizationService.get()
  const [request, setRequest] = useState({
    email: '',
    password: '',
  })
  const { showNotification } = useContext(NotificationContext)

  const login = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const loginResponse = await authenticationService
      .login(request)
      .catch((e) => {
        showNotification(
          NotificationTypeEnum.Error,
          locale.login.errorLogin,
          locale.login.errorLoginSubtitle,
          4000
        )
      })

    if (loginResponse?.success && loginResponse?.data?.token) {
      authenticationService.setToken(loginResponse?.data?.token)
      organizationService.removeOrgId()

      showNotification(
        NotificationTypeEnum.Success,
        locale.login.successLogin,
        null,
        3000
      )
      navigate(ROUTES.HOME)
      return
    }

    setIsLoading(false)
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center items-center sm:mx-auto sm:w-full sm:max-w-md">
          <img className="h-16" src={webdnaLogo} alt="Workflow" />
          <span className="lg:block inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-500 text-white ml-2">
            BETA
          </span>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h2 className="text-xl leading-6 font-medium text-gray-900 mb-6">
              Reset your password
            </h2>

            <form
              className="space-y-6"
              action=""
              method="POST"
              onSubmit={login}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Username / Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    disabled={isLoading}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) =>
                      setRequest({ ...request, email: e.target.value })
                    }
                    value={request.email}
                  />
                </div>
              </div>

              <div>
                <PrimaryButton
                  variant={BUTTON_VARIANT.WIDE}
                  isLoading={isLoading}
                  type="submit"
                >
                  Reset
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
