import { useQuery } from 'react-query'
import MetadataService from '../services/MetadataService'

export default function useGetMetadata(enabled = true) {
  const metadataService = MetadataService.get()

  return useQuery(['metadata'], () => metadataService.getMetadata(), {
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled,
  })
}
