import { useQuery } from 'react-query'
import { HOOK_KEY } from '../helpers/constants'
import ProjectService from '../services/ProjectService'

export default function useListProjects(orgId) {
  const projectService = ProjectService.get()

  return useQuery(
    [HOOK_KEY.PROJECTS, orgId],
    () => projectService.listProjects(orgId),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      // refetchInterval: 5000, // TODO: Enable once in Prod
      enabled: Boolean(orgId),
    }
  )
}
