import { matchPath } from 'react-router'
import { formatDistance, parseISO } from 'date-fns'

export const classNames = (...classes: string[]) =>
  classes.filter(Boolean).join(' ')

// When adding new environment variables, or updating existing ones, the app must be restarted
export const env = () => {
  const {
    REACT_APP_API_URL: API_URL,
    REACT_APP_MAINTENANCE: MAINTENANCE,
    REACT_APP_STRIPE_PUB_KEY: STRIPE_PUB_KEY,
  } = process.env

  return {
    API_URL,
    MAINTENANCE: MAINTENANCE === 'true',
    STRIPE_PUB_KEY,
  }
}

export const slugifyName = (name: string) =>
  name
    .replace(/\s+/g, '-')
    .replace(/[^A-Za-z0-9-]/g, '')
    .toLowerCase()

export const formatUrl = (url: string, ...params: any[]) => {
  let newUrl = url
  params.map((param) => {
    newUrl = newUrl.replace(/:[a-zA-Z]+/, param?.toString())
    return param
  })

  return newUrl
}

export const isInMaintenanceMode =
  env().MAINTENANCE && localStorage.getItem('maintenance') !== 'true'

export const isRoute = (route: string, pathname: string) =>
  Boolean(
    matchPath(
      {
        path: route,
      },
      pathname
    )
  )

export const timeAgo = (dateTime: string, shortForm: boolean = false) =>
  formatDistance(parseISO(dateTime), new Date(), {
    addSuffix: true,
  })

export const sleep = (ms: number) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(null)
    }, ms)
  })

const defaultExport = {}
export default defaultExport
