import Singleton from './Singleton'
import HttpService from './HttpService'
import IChangePasswordRequest from '../interfaces/IChangePasswordRequest'

export default class UserService extends Singleton {
  private constructor() {
    super()
  }

  async getUser() {
    const httpService = HttpService.get()

    const response = await httpService.getUser()

    // TODO: Process Response

    return response?.data
  }

  async getDashboard() {
    const httpService = HttpService.get()

    const response = await httpService.getDashboard()

    // TODO: Process Response

    return response?.data
  }

  async changePassword(request: IChangePasswordRequest) {
    const httpService = HttpService.get()

    const response = await httpService.changePassword(request)

    return response?.data
  }
}
