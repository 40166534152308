import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import NotificationContext from '../context/NotificationContext'
import NotificationTypeEnum from '../enums/NotificationTypeEnum'
import { ROUTES } from '../helpers/constants'
import AuthenticationService from '../services/AuthenticationService'
import locale from '../translations/en'

export default function Logout() {
  const authenticationService = AuthenticationService.get()
  const { showNotification } = useContext(NotificationContext)
  const navigate = useNavigate()

  useEffect(async () => {
    await authenticationService.logout()

    showNotification(
      NotificationTypeEnum.Success,
      locale.logout.success,
      null,
      3000
    )

    navigate(ROUTES.LOGIN)
  }, [])

  return 'Logging out...'
}
