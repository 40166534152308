import { useContext, useState } from 'react'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  API_ROUTES,
  COLOR,
  DB_ENGINE_NAMES,
  HOOK_KEY,
  ROUTES,
  ROUTE_ENUM_MAP,
  TASK_CPU_LIST,
  TASK_MEMORY_NAMES,
} from '../../helpers/constants'
import Badge from '../Badge'
import ProjectService from '../../services/ProjectService'
import { PrimaryButton, SecondaryButton } from '../Form/Button'
import Modal from '../Modal'
import { formatUrl } from '../../helpers/functions'
import { useQueryClient } from 'react-query'
import Alert from '../_shared/Alert'
import NotificationTypeEnum from '../../enums/NotificationTypeEnum'
import locale from '../../translations/en'
import NotificationContext from '../../context/NotificationContext'
import ProjectConfigureStepEnum from '../../enums/ProjectConfigureStepEnum'
import { Grid, Col } from '../Layout/Grid'
import Card from '../Layout/Card'
import Input from '../_shared/Input'
import FormLabel from '../Form/FormLabel'
import FormRow from '../Form/FormRow'
import Heading from '../Heading'

function ProjectPreviewTableRow({ label, content }) {
  return <div className="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4">
    <dt className="text-sm font-medium text-gray-500">
      {label}
    </dt>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
      {content}
    </dd>
  </div>;
}

export default function ProjectPreviewTable({ project }) {
  const projectService = ProjectService.get()
  const navigate = useNavigate()
  const { organizationSlug, projectSlug } = useParams()
  const queryClient = useQueryClient()
  const { showNotification } = useContext(NotificationContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const [isLegalAccepted, setIsLegalAccepted] = useState(false)
  const [isRiskAccepted, setIsRiskAccepted] = useState(false)
  const [isMinimized, setIsMinimized] = useState({
    general: false,
    configuration: false,
    domain: false,
    database: false,
    scm: false,
  })

  const triggerDeployment = async () => {
    const response = await projectService.deploy(project.id).catch((e) => {
      setIsModalOpen(false)
      showNotification(
        NotificationTypeEnum.Error,
        locale.project.errorDeploy,
        e?.response?.data?.message,
        5000
      )
    })

    if (response?.success) {
      await queryClient.invalidateQueries([HOOK_KEY.PROJECT]) // TODO: May not be needed since enabled is false

      navigate(
        formatUrl(
          ROUTES.PROJECT_DETAILS,
          project.organization.slug,
          project.slug
        )
      )

      return
    }
  }

  const triggerCheckoutFlow = async () => {
    const response = await projectService.createCheckoutSession(project.id).catch((e) => {
      setIsModalOpen(false)
      showNotification(
        NotificationTypeEnum.Error,
        locale.project.errorCheckoutSession,
        e?.response?.data?.message,
        5000
      )
    })

    if (response?.success && response?.data?.url) {
      window.location.href = response.data.url

      return
    }
  }

  const MinimizeToggle = ({ section }) =>
    isMinimized[section] ? (
      <ChevronUpIcon
        className="w-6 h-6 hover:cursor-pointer"
        onClick={() =>
          setIsMinimized((m) => ({ ...m, [section]: !m[section] }))
        }
      />
    ) : (
      <ChevronDownIcon
        className="w-6 h-6 hover:cursor-pointer"
        onClick={() =>
          setIsMinimized((m) => ({ ...m, [section]: !m[section] }))
        }
      />
    )

  return (
    <>
      <Grid cols={3}>
        <Col span={2} className="space-y-4">
          {/* Project Summary */}
          <Card header={<Heading size={1}>Project Summary</Heading>}>
            <div className='font-medium'>
              WebDNA will create infrastructure on your AWS cloud environment, which includes the following services:
            </div>
            <div className='text-sm text-gray-500 mt-2'>
              Elastic Container Service (Cluster, Service, Tasks),
              Elastic Container Registry,
              Application Load Balancer,
              Networking - VPC, Internet Gateway, Subnets, Security Groups, Target Groups,
              IAM - Roles, Policies, Users,
              Elastic File System
            </div>
            <div className='font-medium mt-6'>The following services are optional, based on your selections:</div>
            <div className='text-sm text-gray-500 mt-2'>
              Route 53 records,
              Certificate Manager,
              Relational Database Service,
              Secrets Manager,
              CloudWatch
            </div>
            <div className='inline-flex font-medium mt-6'>
              <ExclamationIcon className='h-6 w-6 text-yellow-500 mr-2' />Please verify the details below to ensure proper setup.
            </div>
          </Card>

          {/* General */}
          <Card>
            {/* Heading */}
            <div className="flex items-center justify-between">
              <div className="inline-flex items-baseline">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  General
                </h3>
                <Link
                  to={formatUrl(
                    ROUTE_ENUM_MAP[ProjectConfigureStepEnum.General],
                    project.organization.slug,
                    project.slug
                  )}
                  className="ml-4 text-sm text-blue-500 font-medium"
                >
                  Edit
                </Link>
              </div>
              <div className="text-sm text-gray-500">
                <MinimizeToggle section="general" />
              </div>
            </div>

            {/* Content */}
            {!isMinimized.general && (
              <dl className="sm:divide-y sm:divide-gray-200 mt-4">
                <ProjectPreviewTableRow label='Project name' content={project.name} />
                <ProjectPreviewTableRow label='Project identifier' content={project.slug} />
                <ProjectPreviewTableRow label='Project owner' content={project.owner_name} />
                <ProjectPreviewTableRow label='AWS region' content={project.aws_region} />
                <ProjectPreviewTableRow label='Description' content={project.description || <div className='italic text-gray-500'>N/A</div>} />
                <ProjectPreviewTableRow label='Application type' content={project.stack.name} />
              </dl>
            )}
          </Card>

          {/* Configuration */}
          {Boolean(project.task_configuration) && (<Card>
            {/* Heading */}
            <div className="flex items-center justify-between">
              <div className="inline-flex items-baseline">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Configuration
                </h3>
                <Link
                  to={formatUrl(
                    ROUTE_ENUM_MAP[ProjectConfigureStepEnum.Configuration],
                    project.organization.slug,
                    project.slug
                  )}
                  className="ml-4 text-sm text-blue-500 font-medium"
                >
                  Edit
                </Link>
              </div>
              <div className="text-sm text-gray-500">
                <MinimizeToggle section="configuration" />
              </div>
            </div>

            {/* Content */}
            {!isMinimized.configuration && (
              <dl className="sm:divide-y sm:divide-gray-200 mt-4">
                <ProjectPreviewTableRow label='CPU' content={TASK_CPU_LIST[project.task_configuration.cpu]} />
                <ProjectPreviewTableRow label='Memory (RAM)' content={TASK_MEMORY_NAMES[project.task_configuration.memory]} />
                <ProjectPreviewTableRow label='Enable Autoscaling' content={<div className='italic text-gray-500'>{project.task_configuration.autoscaling_enabled
                  ? 'Yes'
                  : 'No'}</div>} />
                {Boolean(
                  project.task_configuration.autoscaling_enabled
                ) ? (
                  <>

                    <ProjectPreviewTableRow label='Mininum number of replicas' content={project.task_configuration.min_count} />
                    <ProjectPreviewTableRow label='Maximum number of replicas' content={project.task_configuration.max_count} />
                    <ProjectPreviewTableRow label='Autoscaling CPU threshold' content={`${project.task_configuration
                      .autoscaling_cpu_threshold
                      }%`} />
                    <ProjectPreviewTableRow label='Autoscaling Memory threshold' content={`${project.task_configuration
                      .autoscaling_memory_threshold
                      }%`} />
                  </>
                ) : (
                  <ProjectPreviewTableRow label='Number of server replicas' content={project.task_configuration.desired_count} />
                )}
                <ProjectPreviewTableRow label='Enable CloudWatch logging' content={<div className='italic text-gray-500'>{project.task_configuration.cloudwatch_enabled
                  ? 'Yes'
                  : 'No'}</div>} />
                <ProjectPreviewTableRow label='Cloud credentials' content={project.cloud_credentials?.name || 'N/A'} />
              </dl>
            )}
          </Card>)}

          {/* Domain */}
          {Boolean(project.features.create_domain_record) && (
            <Card>
              {/* Heading */}
              <div className="flex items-center justify-between">
                <div className="inline-flex items-baseline">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Domain
                  </h3>
                  <Link
                    to={formatUrl(
                      ROUTE_ENUM_MAP[ProjectConfigureStepEnum.AddOns],
                      project.organization.slug,
                      project.slug
                    )}
                    className="ml-4 text-sm text-blue-500 font-medium"
                  >
                    Edit
                  </Link>
                </div>
                <div className="text-sm text-gray-500">
                  <MinimizeToggle section="domain" />
                </div>
              </div>

              {/* Content */}
              {!isMinimized.domain && (
                <dl className="sm:divide-y sm:divide-gray-200 mt-4">
                  <ProjectPreviewTableRow label='Create domain record' content={<div className='italic text-gray-500'>{project.features.create_domain_record ? 'Yes' : 'No'}</div>} />
                  <ProjectPreviewTableRow label='AWS Hosted Zone name' content={<div className='font-mono'>{project.domain_configuration.aws_hosted_zone}</div>} />
                  <ProjectPreviewTableRow label='Domain name' content={<div className='font-mono'>{project.domain_configuration.domain_name}</div>} />
                  <ProjectPreviewTableRow label='Add AWS self-signed SSL certificate' content={<div className='italic text-gray-500'>{project.domain_configuration.create_ssl
                    ? 'Yes'
                    : 'No'}</div>} />
                  <ProjectPreviewTableRow label='Create a www. alias record in Route 53' content={<div className='italic text-gray-500'>{project.domain_configuration.create_ssl
                    ? 'Yes'
                    : 'No'}</div>} />
                </dl>
              )}
            </Card>
          )}

          {/* Database */}
          {Boolean(project.features.create_database) && (
            <Card>
              {/* Heading */}
              <div className="flex items-center justify-between">
                <div className="inline-flex items-baseline">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Database
                  </h3>
                  <Link
                    to={formatUrl(
                      ROUTE_ENUM_MAP[ProjectConfigureStepEnum.AddOns],
                      project.organization.slug,
                      project.slug
                    )}
                    className="ml-4 text-sm text-blue-500 font-medium"
                  >
                    Edit
                  </Link>
                </div>
                <div className="text-sm text-gray-500">
                  <MinimizeToggle section="database" />
                </div>
              </div>

              {/* Content */}
              {!isMinimized.database && (
                <dl className="sm:divide-y sm:divide-gray-200 mt-4">
                  <ProjectPreviewTableRow label='Create database' content={<div className='italic text-gray-500'>{project.features.create_database ? 'Yes' : 'No'}</div>} />
                  <ProjectPreviewTableRow label='Database engine' content={DB_ENGINE_NAMES[
                    project.database_configuration.engine
                  ]} />
                  <ProjectPreviewTableRow label='Database engine version' content={project.database_configuration.engine_version} />
                  <ProjectPreviewTableRow label='Database instance type' content={project.database_configuration.instance_type} />
                  <ProjectPreviewTableRow label='Database name' content={<div className='font-mono'>{project.database_configuration.db_name}</div>} />
                  <ProjectPreviewTableRow label='Database username' content={<div className='font-mono'>{project.database_configuration.db_username}</div>} />
                  <ProjectPreviewTableRow label='Database password' content={<div className='italic text-gray-500'>Generated automatically and saved in the AWS Secrets Manager</div>} />
                </dl>
              )}
            </Card>
          )}

          {/* Source Control */}
          {Boolean(project.features.create_repository) && (
            <Card>
              {/* Heading */}
              <div className="flex items-center justify-between">
                <div className="inline-flex items-baseline">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Source Control
                  </h3>
                  <Link
                    to={formatUrl(
                      ROUTE_ENUM_MAP[ProjectConfigureStepEnum.AddOns],
                      project.organization.slug,
                      project.slug
                    )}
                    className="ml-4 text-sm text-blue-500 font-medium"
                  >
                    Edit
                  </Link>
                </div>
                <div className="text-sm text-gray-500">
                  <MinimizeToggle section="scm" />
                </div>
              </div>

              {/* Content */}
              {!isMinimized.scm && (
                <dl className="sm:divide-y sm:divide-gray-200 mt-4">
                  <ProjectPreviewTableRow label='Create source control repository' content={<div className='italic text-gray-500'>{project.features.create_repository ? 'Yes' : 'No'}</div>} />
                  <ProjectPreviewTableRow label='New GitHub Repository location' content={<div className='inline-flex items-center'>github.com/{project.scm_configuration.scm_organization.name}/<div className='font-semibold font-mono'>{project.scm_configuration.repo_name}</div></div>} />
                  <ProjectPreviewTableRow label='Pre-load Framework' content={project.scm_configuration.framework.name} />
                </dl>
              )}
            </Card>
          )}
        </Col>

        {/* Checkout */}
        <Col span={1}>
          {/* Payment Flow */}
          {!project.deployed_runs_exists ? (
            <Card className='bg-gray-500'>
              {Boolean(project.task_configuration) ? (
                <>
                  <div>
                    <Input
                      type="checkbox"
                      id="accepted_terms"
                      name="accepted_terms"
                      onChange={(e) => {
                        setIsTermsAccepted((t) =>
                          !Boolean(t)
                        )
                      }}
                    />
                    <span className='text-sm font-medium ml-2'>
                      I certify that I have reviewed the infrastructure changes, and I allow WebDNA to create resources on my AWS account.
                    </span>
                  </div>
                  <div className='mt-2'>
                    <Input
                      type="checkbox"
                      id="accepted_legal"
                      name="accepted_legal"
                      onChange={(e) => {
                        setIsLegalAccepted((t) =>
                          !Boolean(t)
                        )
                      }}
                    />
                    <span className='text-sm font-medium ml-2'>
                      I agree to WebDNA <Link
                        target={'_blank'}
                        to={formatUrl(
                          ROUTES.TERMS
                        )}
                        className='text-blue-600 hover:underline'
                      >Terms</Link> and <Link
                        target={'_blank'}
                        to={formatUrl(
                          ROUTES.PRIVACY
                        )}
                        className='text-blue-600 hover:underline'
                      >Privacy Policy</Link>
                    </span>
                  </div>
                  <div>
                    <div className="mt-4 text-center">
                      <SecondaryButton
                        className="mr-2"
                        onClick={() => {
                          navigate(
                            formatUrl(
                              ROUTES.PROJECT_CONFIGURE.ADD_ONS,
                              organizationSlug,
                              projectSlug
                            )
                          )
                        }}
                      >
                        Back
                      </SecondaryButton>
                      <PrimaryButton onClick={() => setIsModalOpen(true)} disabled={!isTermsAccepted || !isLegalAccepted}>
                        Checkout & Deploy
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Alert
                    color={COLOR.YELLOW}
                    subtitle="You must configure your service first, before you can deploy it"
                  />
                  <Link
                    to={formatUrl(
                      ROUTE_ENUM_MAP[ProjectConfigureStepEnum.Configuration],
                      project.organization.slug,
                      project.slug
                    )}
                  >
                    <PrimaryButton className="mt-4">
                      Go back to Configure
                    </PrimaryButton>
                  </Link></>
              )}
            </Card>
          ) : (
            <Card className='bg-gray-500'>
              <div>
                Your infrastructure is live - please proceed at your own risk. Making updates may cause certain services to be deleted or recreated.
              </div>
              <div className='mt-2'>
                <Input
                  type="checkbox"
                  id="accepted_risk"
                  name="accepted_risk"
                  onChange={(e) => {
                    setIsRiskAccepted((t) =>
                      !Boolean(t)
                    )
                  }}
                />
                <span className='text-sm font-medium ml-2'>
                  I accept the risk and understand that my live AWS infrastructure may be impacted by these changes.
                </span>
              </div>
              <div className="mt-4 text-center">
                <SecondaryButton
                  className="mr-2"
                  onClick={() => {
                    navigate(
                      formatUrl(
                        ROUTES.PROJECT_CONFIGURE.ADD_ONS,
                        organizationSlug,
                        projectSlug
                      )
                    )
                  }}
                >
                  Back
                </SecondaryButton>
                <PrimaryButton onClick={() => setIsModalOpen(true)} disabled={!isRiskAccepted}>
                  Deploy
                </PrimaryButton></div>
            </Card>
          )}
        </Col>
      </Grid >

      {project.deployed_runs_exists ?
        <Modal
          isOpen={isModalOpen}
          onClick={triggerDeployment}
          onClose={() => setIsModalOpen(false)}
          showClose={false}
          title="Ready to Deploy?"
          text=""
          primaryButtonText="Deploy"
        /> : <Modal
          isOpen={isModalOpen}
          onClick={triggerCheckoutFlow}
          onClose={() => setIsModalOpen(false)}
          showClose={false}
          title="Continue to Checkout?"
          text="You will now be redirected to the checkout page to pre-authorize your payment method. You will only be charged once WebDNA successfully creates your cloud infrastructure."
          primaryButtonText="Checkout"
        />}
    </>
  )
}
