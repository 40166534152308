import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import useGetProject from '../../hooks/useGetProject'
import { ROUTES } from '../../helpers/constants.ts'
import Layout from '../Layout'
import { formatUrl } from '../../helpers/functions'
import ProjectRunStateEnum from '../../enums/ProjectRunStateEnum'
import ProjectDetails from './ProjectDetails'
import { PrimaryButton, SecondaryButton } from '../Form/Button'
import { CogIcon, TrashIcon } from '@heroicons/react/solid'
import Modal from '../Modal'
import ProjectService from '../../services/ProjectService'
import NotificationContext from '../../context/NotificationContext'
import NotificationTypeEnum from '../../enums/NotificationTypeEnum'

export default function ProjectDetailsContainer() {
  const projectService = ProjectService.get()
  const { organizationSlug, projectSlug } = useParams()
  const { showNotification } = useContext(NotificationContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pageTitle, setPageTitle] = useState(null)
  const navigate = useNavigate()
  const { isLoading, data: projectData } = useGetProject(
    organizationSlug,
    projectSlug,
    10000
  )
  const [project, setProject] = useState(null)
  const isProjectInProgress = project?.latest_run?.state === ProjectRunStateEnum.Queued || project?.latest_run?.state === ProjectRunStateEnum.InProgress;
  const breadcrumbs = project && [
    {
      name: project.organization?.name,
      path: formatUrl(
        ROUTES.ORGANIZATION_DETAILS.GENERAL,
        project.organization?.slug
      ),
    },
    {
      name: 'Projects',
      // TODO: Re-enable when Org->Projects section is completed
      // path: formatUrl(
      //   ROUTES.ORGANIZATION_DETAILS.PROJECTS,
      //   project.organization?.slug
      // ),
    },
    {
      name: project.name,
    },
  ]

  const archiveProject = async () => {
    const response = await projectService.archive(project.id)

    if (response?.success) {
      showNotification(
        NotificationTypeEnum.Success,
        response.message,
        null,
        5000
      )

      navigate(formatUrl(ROUTES.PROJECTS))

      return
    }
  }

  // Sets page title
  // Auto-redirect to the Configure page
  // Sets default `project` value
  useEffect(() => {
    if (isLoading || !projectData) {
      return
    }

    setPageTitle(projectData.name)
    setProject(projectData)

    console.log(projectData);

    if (
      !projectData.deployed_runs_exists &&
      (!projectData.latest_run || projectData.latest_run?.state === ProjectRunStateEnum.Created)
    ) {
      navigate(
        formatUrl(
          ROUTES.PROJECT_CONFIGURE.GENERAL,
          organizationSlug,
          projectSlug
        ),
        { replace: true }
      )
      return
    }
  }, [projectData, isLoading, navigate, organizationSlug, projectSlug])

  return (
    <Layout
      title={pageTitle}
      subtitle={project?.description}
      breadcrumbs={breadcrumbs}
      secondaryButton={
        !isProjectInProgress ? <SecondaryButton className="mr-2" onClick={() => setIsModalOpen(true)}>
          <TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Archive
        </SecondaryButton> : <></>
      }
      primaryButton={
        <Link
          to={formatUrl(
            ROUTES.PROJECT_CONFIGURE.GENERAL,
            organizationSlug,
            projectSlug
          )}
        >
          <PrimaryButton disabled={isProjectInProgress}>
            <CogIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Configure
          </PrimaryButton>
        </Link>
      }
    >
      {!isLoading && project && <ProjectDetails project={project} />}

      <Modal
        isOpen={isModalOpen}
        onClick={archiveProject}
        onClose={() => setIsModalOpen(false)}
        showClose={false}
        title="Are you sure?"
        text="Your cloud resources will not be affected by this action."
        primaryButtonText="Archive"
      />
    </Layout>
  )
}
