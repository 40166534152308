import { PlusIcon } from '@heroicons/react/outline'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BUTTON_SIZE, COLOR, ROUTES } from '../../../helpers/constants'
import { formatUrl } from '../../../helpers/functions'
import useGetOrganization from '../../../hooks/useGetOrganization'
import useListSCMCredentials from '../../../hooks/useListSCMCredentials'
import { PrimaryButton, SecondaryButton } from '../../Form/Button'
import Heading from '../../Heading'
import Layout from '../../Layout'
import Card from '../../Layout/Card'
import { Grid, Col } from '../../Layout/Grid'
import CreateGithubAccessSlideout from './CreateGithubAccessSlideout'
import OrganizationSettingsNav from './OrganizationSettingsNav'
import SCMProviderEnum from '../../../enums/SCMProviderEnum'
import githubLogo from '../../../images/github-logo.svg'
import Modal from '../../Modal'
import SCMOrganizationsManagement from './SCMOrganizationsManagement'
import NotificationContext from '../../../context/NotificationContext'
import NotificationTypeEnum from '../../../enums/NotificationTypeEnum'
import OrganizationService from '../../../services/OrganizationService'

export default function OrganizationSettingsAccessGithub() {
  const organizationService = OrganizationService.get()
  const { showNotification } = useContext(NotificationContext)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isManagePopupOpen, setIsManagePopupOpen] = useState(false)
  const { organizationSlug } = useParams()
  const [organizationId, setOrganizationId] = useState(null)
  const [enabledSCMOrgsUpdated, setEnabledSCMOrgsUpdated] = useState(false)
  const [credentialToUpdate, setCredentialToUpdate] = useState(null)
  const [sourceControlCredentials, setSourceControlCredentials] = useState(null)
  const { isLoading, data: organizationDetails } =
    useGetOrganization(organizationSlug)
  const {
    data: sourceControlCredentialsResponse,
    refetch: refetchSourceControlCredentials,
  } = useListSCMCredentials(organizationId)

  const onCreate = () => {
    setIsFormOpen(false)
    refetchSourceControlCredentials()
  }

  const onOrgsUpdated = (org) => {
    setEnabledSCMOrgsUpdated(true)

    setCredentialToUpdate((c) => {
      c.scm_organizations = c.scm_organizations.map((scm_org) => {
        if (scm_org.id === org.id) {
          scm_org.enabled = org.enabled
        }

        return scm_org
      })

      return c
    })
  }

  const onEnabledSCMOrgsSave = async () => {
    if (!credentialToUpdate) {
      return null
    }

    const response = await organizationService.updateSCMOrganizations(
      credentialToUpdate.organization_id,
      credentialToUpdate.id,
      credentialToUpdate.scm_organizations
    )

    if (response?.success) {
      showNotification(
        NotificationTypeEnum.Success,
        response.message,
        null,
        5000
      )

      setIsManagePopupOpen(false)
      refetchSourceControlCredentials()

      return
    } else {
      // TODO: Handle error
    }
  }

  useEffect(() => {
    if (!organizationDetails) {
      return
    }

    setOrganizationId(organizationDetails?.id)
  }, [organizationDetails])

  useEffect(() => {
    if (sourceControlCredentialsResponse?.success) {
      setSourceControlCredentials(sourceControlCredentialsResponse?.data)
    }
  }, [sourceControlCredentialsResponse])

  const breadcrumbs = [
    {
      name: organizationDetails?.name,
      path: formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, organizationSlug),
    },
    {
      name: 'Settings',
    },
  ]

  return (
    <Layout
      title={organizationDetails?.name}
      breadcrumbs={breadcrumbs}
      nav={
        <OrganizationSettingsNav
          organizationSlug={organizationSlug}
          currentRoute={ROUTES.ORGANIZATION_SETTINGS.ACCESS_GITHUB}
        />
      }
    >
      <Modal
        isOpen={isManagePopupOpen}
        title="Authorized GitHub Organizations"
        onClose={() => {
          setIsManagePopupOpen(false)
          setEnabledSCMOrgsUpdated(false)
          setCredentialToUpdate(null)
        }}
        onClick={onEnabledSCMOrgsSave}
        primaryButtonDisabled={!enabledSCMOrgsUpdated}
        primaryButtonText="Save changes"
        showClose={false}
      >
        {credentialToUpdate && (
          <SCMOrganizationsManagement
            list={credentialToUpdate.scm_organizations}
            onOrgsUpdated={onOrgsUpdated}
          />
        )}
      </Modal>
      <Card
        header={
          <Grid cols={2}>
            <Col span={1} className="flex items-center">
              <div>
                <Heading size={3}>GitHub Access</Heading>
                <p className="text-sm text-gray-500">
                  Set up access to your GitHub account
                </p>
              </div>
            </Col>
            <Col span={1} className="text-right">
              <PrimaryButton
                onClick={() => {
                  setIsFormOpen(true)
                }}
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Add
              </PrimaryButton>
            </Col>
          </Grid>
        }
      >
        {sourceControlCredentials?.length > 0 ? (
          <div className="space-y-6">
            <Grid>
              {sourceControlCredentials.map((credential) => (
                <Fragment key={credential.id}>
                  <Col span={4} className="flex items-center">
                    <div className="flex items-center">
                      {credential.provider === SCMProviderEnum.Github && (
                        <div className="self-baseline">
                          <img
                            className="mr-1.5 w-5 h-5"
                            src={githubLogo}
                            alt="GitHub"
                          />
                        </div>
                      )}
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {credential.name}
                        </div>
                        {/** TODO: Show enabled orgs below */}
                        {/* <div className="text-sm text-gray-500">
                          3 organizations authorized
                        </div> */}
                      </div>
                    </div>
                  </Col>
                  <Col span={2} className="text-right">
                    <SecondaryButton
                      onClick={() => {
                        setCredentialToUpdate(credential)
                        setIsManagePopupOpen(true)
                      }}
                      size={BUTTON_SIZE.SMALL}
                      className="mr-2"
                    >
                      Manage
                    </SecondaryButton>
                    <PrimaryButton
                      size={BUTTON_SIZE.SMALL}
                      color={COLOR.RED}
                      disabled
                    >
                      Delete
                    </PrimaryButton>
                  </Col>
                </Fragment>
              ))}
            </Grid>
          </div>
        ) : (
          <div className="text-sm text-center text-gray-500">
            No credentials found
          </div>
        )}
      </Card>
      <CreateGithubAccessSlideout
        open={isFormOpen}
        onClose={() => {
          setIsFormOpen(false)
        }}
        organizationId={organizationId}
        onCreate={onCreate}
      />
    </Layout>
  )
}
