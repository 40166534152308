import {
  ChevronRightIcon,
  CollectionIcon,
  UsersIcon,
} from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { COLOR, ROLE_NAME, ROUTES } from '../../helpers/constants'
import { formatUrl } from '../../helpers/functions'
import Badge from '../Badge'

export default function OrganizationList({ organizations }) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {organizations?.map((organization) => (
          <li key={organization.id}>
            <Link
              to={formatUrl(
                ROUTES.ORGANIZATION_DETAILS.GENERAL,
                organization.slug
              )}
              className="block hover:bg-gray-50"
            >
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm">
                      <p className="font-medium text-blue-600 truncate">
                        {organization.name}
                      </p>
                      <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                        {organization.slug}
                      </p>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <UsersIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>Members: {organization.members_count}</p>
                      </div>
                      <div className="flex items-center text-sm text-gray-500 ml-4">
                        <CollectionIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>Projects: {organization.projects_count}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex overflow-hidden -space-x-1">
                      <Badge color={COLOR.GRAY}>
                        {ROLE_NAME[organization.membership?.role]}
                      </Badge>
                    </div>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
