import { PlusIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import useListOrganizations from '../../hooks/useListOrganizations'
import { PrimaryButton } from '../Form/Button'
import Layout from '../Layout'
import LoadingCard from '../LoadingCard'
import EmptyListState from '../EmptyListState'
import CreateOrganizationSlideout from './CreateOrganizationSlideout'
import OrganizationList from './OrganizationsList'

export default function OrganizationsContainer() {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const { isLoading, data: organizations } = useListOrganizations()

  const breadcrumbs = [
    {
      name: 'Organizations',
    },
  ]

  const onCreateClick = () => {
    setIsFormOpen(true)
  }

  const OrganizationsBodyComponent = () => {
    if (isLoading || !organizations) return <LoadingCard />
    if (organizations?.length === 0)
      return (
        <EmptyListState
          title="No organizations found"
          subtitle="Get started by creating your first organization."
          buttonText="New Organization"
          onCreateClick={onCreateClick}
        />
      )
    return <OrganizationList organizations={organizations} />
  }

  return (
    <Layout
      title="Organizations"
      breadcrumbs={breadcrumbs}
      primaryButton={
        <PrimaryButton
          onClick={() => {
            setIsFormOpen(true)
          }}
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Organization
        </PrimaryButton>
      }
    >
      <OrganizationsBodyComponent />
      <CreateOrganizationSlideout
        open={isFormOpen}
        onClose={() => {
          setIsFormOpen(false)
        }}
      />
    </Layout>
  )
}
