import NavBar from '../components/NavBar'
import { classNames } from '../helpers/functions'
import Footer from './Footer'
import LayoutHeader from './LayoutHeader'

export default function Layout(props) {
  const { children, title, nav } = props

  return (
    <>
      <NavBar />

      <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        {Boolean(title) && <LayoutHeader {...props} />}

        <div className="lg:grid lg:grid-cols-12 lg:gap-6">
          {Boolean(nav) && <aside className="lg:col-span-3">{nav}</aside>}
          <div
            className={classNames(
              Boolean(nav) ? 'lg:col-span-9' : 'lg:col-span-12'
            )}
          >
            {children}
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
