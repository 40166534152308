import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { useCallback } from 'react'
import { COLOR } from '../../helpers/constants'
import { classNames } from '../../helpers/functions'

export default function Alert({
  color = COLOR.YELLOW,
  title,
  subtitle,
  className,
}) {
  const Icon = useCallback(() => {
    switch (color) {
      case COLOR.YELLOW:
        return (
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        )
      case COLOR.BLUE:
        return (
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        )
      default:
        return <></>
    }
  }, [color])

  return (
    <div
      className={classNames(
        'rounded-md p-4 text-left',
        `bg-${color}-50`,
        className
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon />
        </div>
        <div className="ml-3">
          <h3
            className={classNames('text-sm font-medium', `text-${color}-800`)}
          >
            {title}
          </h3>
          {Boolean(subtitle) && (
            <div
              className={classNames(
                'text-sm',
                `text-${color}-700`,
                Boolean(title) && 'mt-2'
              )}
            >
              {subtitle}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
