import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatUrl, slugifyName } from '../../helpers/functions'
import OrganizationService from '../../services/OrganizationService'
import NotificationTypeEnum from '../../enums/NotificationTypeEnum'
import locale from '../../translations/en'
import NotificationContext from '../../context/NotificationContext'
import Slideout from '../_shared/Slideout'
import { HOOK_KEY, ROUTES } from '../../helpers/constants'
import { useQueryClient } from 'react-query'
import FormRow from '../Form/FormRow'
import FormLabel from '../Form/FormLabel'
import Input from '../_shared/Input'

export default function CreateOrganizationSlideout({ open, onClose }) {
  const organizationService = OrganizationService.get()
  const navigate = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const queryClient = useQueryClient()

  const [isLoading, setIsLoading] = useState(false)
  const [request, setRequest] = useState({
    name: '',
    slug: '',
    description: '',
  })

  const createOrganization = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const createResponse = await organizationService
      .createOrganization(request)
      .catch((e) => {
        // TODO: Display error banner
        showNotification(
          NotificationTypeEnum.Error,
          locale.organization.errorCreate,
          e?.response?.data?.message,
          5000
        )
      })

    if (createResponse?.success) {
      showNotification(
        NotificationTypeEnum.Success,
        locale.organization.successCreate,
        locale.organization.successCreateSubtitle,
        3000
      )
      queryClient.invalidateQueries([HOOK_KEY.ORGANIZATIONS])
      navigate(formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, request.slug))

      return
    }

    setIsLoading(false)
  }

  return (
    <Slideout
      open={open}
      onClose={onClose}
      title="New organization"
      subtitle="Get started by filling in the information below to create your new organization."
      onSubmit={createOrganization}
      isLoading={isLoading}
    >
      <div className="py-6 px-4 space-y-6 sm:p-6">
        <div>
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="name">Organization name</FormLabel>}
          >
            <Input
              name="name"
              id="name"
              placeholder="My Business Inc."
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  name: e.target.value,
                }))
              }
              value={request.name}
              onBlur={() => {
                if (request.slug.length === 0) {
                  setRequest((r) => ({
                    ...r,
                    slug: slugifyName(request.name),
                  }))
                }
              }}
              required
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={
              <FormLabel htmlFor="slug">Organization identifier</FormLabel>
            }
          >
            <Input
              name="slug"
              id="slug"
              placeholder="my-business-inc"
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  slug: e.target.value,
                }))
              }
              value={request.slug}
              required
            />
            <p className="mt-2 text-sm text-gray-500">Unique URL identifier</p>
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="description">Description</FormLabel>}
          >
            <textarea
              id="description"
              name="description"
              rows={3}
              className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  description: e.target.value,
                }))
              }
              value={request.description}
            />
          </FormRow>
        </div>
      </div>
    </Slideout>
  )
}
