import { Col, Grid } from '../Layout/Grid'

export default function FormRow({
  cols = 6,
  gap = 4,
  labelSpan = 2,
  contentSpan = 2,
  label,
  children,
}) {
  return (
    <Grid cols={cols} gap={gap}>
      <Col span={labelSpan}>{label}</Col>
      <Col span={contentSpan}>{children}</Col>
    </Grid>
  )
}
