import { COLOR } from '../helpers/constants'
import { classNames } from '../helpers/functions'

export default function Badge({
  children,
  color = COLOR.BLUE,
  className,
  title = null,
}) {
  return (
    <span
      className={classNames(
        `bg-${color}-100 text-${color}-800`,
        'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
        className
      )}
      title={title}
    >
      {children}
    </span>
  )
}
