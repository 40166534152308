import { classNames } from '../../helpers/functions'

export default function FormLabel({
  htmlFor,
  className,
  textColor = 'text-gray-900',
  noPad = false,
  block = true,
  children,
}) {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        'text-sm font-medium hover:cursor-pointer',
        block && 'block',
        !noPad && 'sm:mt-px sm:pt-2',
        textColor,
        className
      )}
    >
      {children}
    </label>
  )
}
