import { useState, useContext } from 'react'
import OrganizationService from '../../../services/OrganizationService'
import NotificationTypeEnum from '../../../enums/NotificationTypeEnum'
import locale from '../../../translations/en'
import NotificationContext from '../../../context/NotificationContext'
import Slideout from '../../_shared/Slideout'
import FormRow from '../../Form/FormRow'
import FormLabel from '../../Form/FormLabel'
import Input from '../../_shared/Input'
import Alert from '../../_shared/Alert'
import { COLOR } from '../../../helpers/constants'

export default function CreateAWSAccessSlideout({
  open,
  onClose,
  organizationId,
  onCreate,
}) {
  const organizationService = OrganizationService.get()
  const { showNotification } = useContext(NotificationContext)

  const [isLoading, setIsLoading] = useState(false)
  const [request, setRequest] = useState({
    name: '',
    aws_access_key_id: '',
    aws_secret_access_key: '',
  })

  const createCloudCredentials = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const createResponse = await organizationService
      .createCloudCredentials(organizationId, request)
      .catch((e) => {
        // TODO: Display error banner
        console.error(e)
      })
    if (createResponse?.success) {
      showNotification(
        NotificationTypeEnum.Success,
        locale.cloudCredentials.successCreate,
        null,
        5000
      )
      onCreate()
      return
    }

    setIsLoading(false)
  }

  return (
    <Slideout
      open={open}
      onClose={onClose}
      title="New AWS Credentials"
      subtitle="Get started by filling in the information below to set up new AWS credentials for your organization."
      onSubmit={createCloudCredentials}
      isLoading={isLoading}
    >
      <Alert
        color={COLOR.BLUE}
        title="Instructions"
        subtitle={
          <ul className="list-disc ml-4">
            <li>
              Navigate to the{' '}
              <a
                href="https://us-east-1.console.aws.amazon.com/iamv2/home?region=us-east-1#/users/create"
                target="_blank"
                className="underline hover:no-underline"
              >
                AWS IAM Console
              </a>{' '}
              page
            </li>
            <li>
              Click on{' '}
              <span className="font-bold">Attach policies directly</span>
            </li>
            <li>
              Select <span className="font-bold">AdministratorAccess</span>{' '}
              (this will be more specific in future releases)
            </li>
          </ul>
        }
        className="m-6"
      />

      <div className="py-6 px-4 space-y-6 sm:p-6">
        <div>
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="name">Name</FormLabel>}
          >
            <Input
              name="name"
              id="name"
              placeholder="Main AWS account (test@test.com)"
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  name: e.target.value,
                }))
              }
              value={request.name}
              required
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={
              <FormLabel htmlFor="aws_access_key_id">
                AWS Access Key Id
              </FormLabel>
            }
          >
            <Input
              name="aws_access_key_id"
              id="aws_access_key_id"
              placeholder=""
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  aws_access_key_id: e.target.value,
                }))
              }
              value={request.aws_access_key_id}
              required
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={
              <FormLabel htmlFor="aws_secret_access_key">
                AWS Secret Access Key
              </FormLabel>
            }
          >
            <Input
              name="aws_secret_access_key"
              id="aws_secret_access_key"
              placeholder=""
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  aws_secret_access_key: e.target.value,
                }))
              }
              value={request.aws_secret_access_key}
              required
            />
          </FormRow>
        </div>
      </div>
    </Slideout>
  )
}
