/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

export default function Breadcrumbs({ breadcrumbs }) {
  if (!breadcrumbs) {
    return null
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2">
        <Link to="/" className="text-gray-400 hover:text-gray-700">
          <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
        </Link>
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {breadcrumb.path ? (
                <Link
                  to={breadcrumb.path}
                  className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {breadcrumb.name}
                </Link>
              ) : (
                <span className="ml-2 text-sm font-medium text-gray-500">
                  {breadcrumb.name}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
