import { BREAKPOINT_SIZE } from '../../helpers/constants'
import { classNames } from '../../helpers/functions'

export default function Card({
  header,
  footer,
  children,
  padContent = true,
  showDividers = true,
  grayFooter = true,
  className,
  size = BREAKPOINT_SIZE.SMALL,
}) {
  return (
    <div
      className={classNames(
        'bg-white shadow overflow-hidden',
        `${size}:rounded-md`,
        showDividers && 'divide-y divide-gray-200',
        Boolean(className) && className
      )}
    >
      {/* Header */}
      {header && <div className="px-4 py-5 sm:px-6">{header}</div>}

      {/* Body */}
      <div className={classNames(padContent && 'px-4 py-5 sm:p-6')}>
        {children}
      </div>

      {/* Footer */}
      {footer && (
        <div
          className={classNames(
            'px-4 py-3 sm:px-6',
            grayFooter && 'bg-gray-50'
          )}
        >
          {footer}
        </div>
      )}
    </div>
  )
}
