import { useQuery } from 'react-query'
import OrganizationService from '../services/OrganizationService'

export default function useListSCMCredentials(organizationId) {
  const organizationService = OrganizationService.get()

  return useQuery(
    ['scm_credentials', organizationId],
    () => organizationService.getScmCredentials(organizationId),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(organizationId),
    }
  )
}
