import { useQuery } from 'react-query'
import { HOOK_KEY } from '../helpers/constants'
import AuthenticationService from '../services/AuthenticationService'
import UserService from '../services/UserService'

export default function useGetUser(invalidationKey) {
  const userService = UserService.get()
  const authenticationService = AuthenticationService.get()

  return useQuery(
    [HOOK_KEY.USER, invalidationKey],
    () => userService.getUser(),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(authenticationService.getToken()),
    }
  )
}
