import { useContext, useState } from 'react'
import { PlusIcon } from '@heroicons/react/outline'
import useListProjects from '../../hooks/useListProjects'
import UserContext from '../../context/UserContext'
import LoadingCard from '../LoadingCard'
import EmptyListState from '../EmptyListState'
import ProjectsList from './ProjectsList'
import Layout from '../Layout'
import { PrimaryButton } from '../Form/Button'
import CreateProjectSlideout from './CreateProjectSlideout'
import { ROUTES } from '../../helpers/constants'
import { formatUrl } from '../../helpers/functions'
import ProjectListTabs from './ProjectListTabs'

export default function ProjectsContainer() {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const {
    userContext: { selectedOrg },
  } = useContext(UserContext)

  const { isLoading, data: projects } = useListProjects(selectedOrg?.id)

  const breadcrumbs = selectedOrg && [
    {
      name: selectedOrg.name,
      path: formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, selectedOrg.slug),
    },
    {
      name: 'Projects',
    },
  ]

  const onCreateClick = () => {
    setIsFormOpen(true)
  }

  const ProjectsBodyComponent = () => {
    if (isLoading || !projects) return <LoadingCard />

    return (
      <>
        <ProjectListTabs />
        {projects?.length === 0 ? (
          <EmptyListState
            title="No projects found"
            subtitle="Get started by creating a new project."
            buttonText="New Project"
            onCreateClick={onCreateClick}
          />
        ) : (
          <ProjectsList projects={projects} />
        )}
      </>
    )
  }

  return (
    <Layout
      title="Projects"
      breadcrumbs={breadcrumbs}
      primaryButton={
        projects?.length > 0 && (
          <>
            <PrimaryButton
              onClick={() => {
                setIsFormOpen(true)
              }}
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              New Project
            </PrimaryButton>
          </>
        )
      }
    >
      <ProjectsBodyComponent />
      <CreateProjectSlideout
        open={isFormOpen}
        onClose={() => {
          setIsFormOpen(false)
        }}
      />
    </Layout>
  )
}
