import Singleton from './Singleton'
import HttpService from './HttpService'
import IOrganization from '../interfaces/IOrganization'
import IHttpResponse from '../interfaces/IHttpResponse'
import ICloudCredentials from '../interfaces/ICloudCredentials'
import ISCMOrganization from '../interfaces/ISCMOrganization'
import { API_ROUTES } from '../helpers/constants'
import { formatUrl } from '../helpers/functions'
import ISCMCredentialsRequest from '../interfaces/ISCMCredentialsRequest'

export default class OrganizationService extends Singleton {
  private constructor() {
    super()
  }

  readonly LOCAL_STORAGE_ORG_KEY = 'org-id'

  getOrgId = () => {
    const currentOrgId = localStorage.getItem(this.LOCAL_STORAGE_ORG_KEY)
    if (currentOrgId) {
      return Number(currentOrgId)
    }

    return currentOrgId
  }

  setOrgId = (organizationId: number) =>
    localStorage.setItem(this.LOCAL_STORAGE_ORG_KEY, organizationId.toString())

  removeOrgId = () => localStorage.removeItem(this.LOCAL_STORAGE_ORG_KEY)

  async getOrganization(organizationSlug: string) {
    const httpService = HttpService.get()

    const getResponse = await httpService.getOrganization(organizationSlug)

    // TODO: Process Response

    return getResponse?.data
  }

  async getUserOrgs() {
    const httpService = HttpService.get()

    // TODO: Validate

    const response = await httpService.getUserOrgs()

    // TODO: Process Response

    return response?.data
  }

  async createOrganization(organization: IOrganization) {
    const httpService = HttpService.get()

    // TODO: Validate

    const response = await httpService.createOrganization(organization)

    // TODO: Process Response

    return response?.data
  }

  /**
   * Cloud
   */
  async getCloudCredentials(organizationId: number): Promise<IHttpResponse> {
    const httpService = HttpService.get()
    const response = await httpService.get(
      formatUrl(API_ROUTES.ORGANIZATION.CREDENTIALS.CLOUD.LIST, organizationId)
    )

    return response?.data
  }

  async createCloudCredentials(
    organizationId: number,
    cloudCredentials: ICloudCredentials
  ) {
    const httpService = HttpService.get()

    // TODO: Validate

    const response = await httpService.createCloudCredentials(
      organizationId,
      cloudCredentials
    )

    // TODO: Process Response

    return response?.data
  }

  /**
   * SCM
   */
  async getScmCredentials(organizationId: number) {
    const httpService = HttpService.get()
    const response = await httpService.get(
      formatUrl(API_ROUTES.ORGANIZATION.CREDENTIALS.SCM.LIST, organizationId)
    )

    return response?.data
  }

  async getScmOrganizations(organizationId: number) {
    const httpService = HttpService.get()
    const response = await httpService.get(
      formatUrl(
        API_ROUTES.ORGANIZATION.CREDENTIALS.SCM.LIST_ORGANIZATIONS,
        organizationId
      )
    )

    return response?.data
  }

  async createSCMCredentials(
    organizationId: number,
    credentials: ISCMCredentialsRequest
  ) {
    const httpService = HttpService.get()
    const response = await httpService.post(
      formatUrl(API_ROUTES.ORGANIZATION.CREDENTIALS.SCM.CREATE, organizationId),
      credentials
    )

    return response?.data
  }

  async updateSCMOrganizations(
    organizationId: number,
    scmCredentialsId: number,
    scm_organizations: Array<ISCMOrganization>
  ) {
    const httpService = HttpService.get()
    const response = await httpService.put(
      formatUrl(
        API_ROUTES.ORGANIZATION.CREDENTIALS.SCM.UPDATE_ORGANIZATIONS,
        organizationId,
        scmCredentialsId
      ),
      {
        scm_organizations,
      }
    )

    return response?.data
  }
}
