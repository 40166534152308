import { useState } from 'react'
import SCMOrganizationToggle from './SCMOrganizationToggle'

export default function SCMOrganizationsManagement({ list, onOrgsUpdated }) {
  const [scmOrgs, setScmOrgs] = useState(list)

  const onEnabledOrgsUpdated = (org) => {
    setScmOrgs((c) =>
      c.map((scm_org) => {
        if (scm_org.id === org.id) {
          scm_org.enabled = org.enabled
        }

        return scm_org
      })
    )

    onOrgsUpdated(org)
  }

  if (!scmOrgs) {
    return null
  }

  return (
    <div className="grid gap-2 w-full pt-4">
      <p className="text-sm mb-2">
        Please select which GitHub organizations you would like to give WebDNA
        access to.
      </p>
      {scmOrgs.map((organization) => (
        <SCMOrganizationToggle
          key={organization.id}
          organization={organization}
          onEnabledOrgsUpdated={onEnabledOrgsUpdated}
        />
      ))}
    </div>
  )
}
