import axios from 'axios'
import Singleton from './Singleton'
import { API_ROUTES } from '../helpers/constants'
import { formatUrl } from '../helpers/functions'
import ILoginRequest from '../interfaces/ILoginRequest'
import IProject from '../interfaces/IProject'
import IOrganization from '../interfaces/IOrganization'
import IHttpResponse from '../interfaces/IHttpResponse'
import ICloudCredentials from '../interfaces/ICloudCredentials'
import IChangePasswordRequest from '../interfaces/IChangePasswordRequest'

export default class HttpService extends Singleton {
  private constructor() {
    super()
  }

  async get(url: string) {
    return axios.get(url)
  }

  async post(url: string, body: any = null) {
    return axios.post(url, body)
  }

  async put(url: string, body: any = null) {
    return axios.put(url, body)
  }

  // TODO: Deprecate below this, in favor of generic httpService.get() or .post(), etc

  async postLogin(loginRequest: ILoginRequest) {
    return axios.post(API_ROUTES.LOGIN, loginRequest)
  }

  async logout() {
    return axios.post(API_ROUTES.LOGOUT)
  }

  async getUserOrgs() {
    return axios.get(API_ROUTES.USER_ORGS)
  }

  async getProjects(organizationId: number) {
    return axios.get(formatUrl(API_ROUTES.LIST_PROJECTS, organizationId))
  }

  async createProject(project: IProject) {
    return axios.post(API_ROUTES.CREATE_PROJECT, project)
  }

  async updateProject(project: IProject) {
    return axios.put(formatUrl(API_ROUTES.UPDATE_PROJECT, project.id), project)
  }

  async getProject(organizationSlug: string, projectSlug: string) {
    return axios.get(
      formatUrl(API_ROUTES.PROJECT_DETAILS, organizationSlug, projectSlug)
    )
  }

  async deployProject(projectId: number) {
    return axios.post(formatUrl(API_ROUTES.DEPLOY_PROJECT, projectId))
  }

  async createCheckoutSession(projectId: number): Promise<IHttpResponse> {
    const response = await axios.post(
      formatUrl(API_ROUTES.PROJECT.PAYMENT.CREATE_CHECKOUT_SESSION, projectId)
    )

    return response?.data
  }

  async getOrganization(organizationSlug: string) {
    return axios.get(
      formatUrl(API_ROUTES.ORGANIZATION_DETAILS, organizationSlug)
    )
  }

  async createOrganization(organization: IOrganization) {
    return axios.post(API_ROUTES.CREATE_ORGANIZATION, organization)
  }

  async createCloudCredentials(
    organizationId: number,
    cloudCredentials: ICloudCredentials
  ) {
    return axios.post(
      formatUrl(API_ROUTES.CREATE_CLOUD_CREDENTIALS, organizationId),
      cloudCredentials
    )
  }

  async getUser() {
    return axios.get(API_ROUTES.USER_DETAILS)
  }

  async getDashboard() {
    return axios.get(API_ROUTES.DASHBOARD)
  }

  async changePassword(request: IChangePasswordRequest) {
    return axios.put(API_ROUTES.CHANGE_PASSWORD, request)
  }

  async getMetadata(): Promise<IHttpResponse> {
    const response = await axios.get(API_ROUTES.METADATA)

    return response?.data
  }

  async archiveProject(projectId: number): Promise<IHttpResponse> {
    const response = await axios.post(
      formatUrl(API_ROUTES.ARCHIVE_PROJECT, projectId)
    )

    return response?.data
  }
}
