import { PlusIcon } from '@heroicons/react/outline'
import { FolderAddIcon } from '@heroicons/react/outline'
import { PrimaryButton } from './Form/Button'

export default function EmptyListState({
  title,
  subtitle,
  buttonText,
  onCreateClick,
}) {
  return (
    <div className="rounded-md p-12 border-dashed border-2 border-gray-300">
      <div className="text-center">
        <FolderAddIcon className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
        <div className="mt-6">
          <PrimaryButton onClick={onCreateClick}>
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {buttonText}
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}
