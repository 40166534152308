import Breadcrumbs from './Breadcrumbs'

export default function LayoutHeader({
  title,
  subtitle,
  breadcrumbs,
  info,
  primaryButton,
  secondaryButton,
}) {
  return (
    <div className="lg:flex lg:items-center lg:justify-between mb-6">
      <div className="flex-1 min-w-0">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {title}
        </h2>
        {subtitle && (
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              {subtitle}
            </div>
          </div>
        )}
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        {info && info}
        {secondaryButton && secondaryButton}
        {primaryButton && primaryButton}
      </div>
    </div>
  )
}
