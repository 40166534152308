import { useContext, useEffect, useState } from 'react'
import ProjectConfigureStepEnum from '../../../enums/ProjectConfigureStepEnum'
import TaskSizePicker from '../TaskSizePicker'
import {
  BUTTON_STYLE,
  COLOR,
  ROUTES,
  TASK_CPU_LIST,
  TASK_MEMORY_LIST,
  TASK_SIZE_VARIANT,
} from '../../../helpers/constants'
import Toggle from '../../Toggle'
import ProjectConfigureContext from '../../../context/ProjectConfigureContext'
import { PrimaryButton, SecondaryButton } from '../../Form/Button'
import useListCloudCredentials from '../../../hooks/useListCloudCredentials'
import SelectDropdown from '../../SelectDropdown'
import { PlusIcon, RefreshIcon } from '@heroicons/react/outline'
import { Link, useParams } from 'react-router-dom'
import { classNames, formatUrl } from '../../../helpers/functions'
import FormRow from '../../Form/FormRow'
import FormLabel from '../../Form/FormLabel'
import Input from '../../_shared/Input'
import { Col, Grid } from '../../Layout/Grid'

/**
 * TODO: Add checkbox to update secrets, default/force to true on first run
 */
export default function ECSConfiguration({ title, currentStep, onStepChange }) {
  const DEFAULT_MIN_COUNT = 1
  const DEFAULT_MAX_COUNT = 2
  const DEFAULT_DESIRED_COUNT = 1
  const DEFAULT_CPU_THRESHOLD = 80
  const DEFAULT_MEMORY_THRESHOLD = 80

  const [isLoading, setIsLoading] = useState(BUTTON_STYLE.NONE)
  const [taskConfiguration, setTaskConfiguration] = useState(null)
  const [cloudCredentialsId, setCloudCredentialsId] = useState(null)
  const {
    project: {
      organization_id,
      task_configuration,
      cloud_credentials_id,
      deployed_runs_exists,
    },
  } = useContext(ProjectConfigureContext)

  const { organizationSlug } = useParams()
  const {
    isLoading: isFetchingCredentials,
    data: cloudCredentialsResponse,
    refetch: refetchCloudCredentials,
  } = useListCloudCredentials(organization_id)
  const { success: successFetchingCredentials, data: credentials } =
    cloudCredentialsResponse || {}
  const cloudCredentials = credentials?.map((credential) => ({
    id: credential.id,
    name: credential.name,
    value: credential.id,
  }))

  const goToPrevious = async () => {
    setIsLoading(BUTTON_STYLE.SECONDARY)

    const projectSaveResponse = await onStepChange(
      ProjectConfigureStepEnum.General,
      {
        task_configuration: taskConfiguration,
        cloud_credentials_id: cloudCredentialsId,
      }
    )

    if (!projectSaveResponse) {
      setIsLoading(BUTTON_STYLE.NONE)
    }
  }

  const goToNext = async () => {
    setIsLoading(BUTTON_STYLE.PRIMARY)

    const projectSaveResponse = await onStepChange(
      ProjectConfigureStepEnum.AddOns,
      {
        task_configuration: taskConfiguration,
        cloud_credentials_id: cloudCredentialsId,
      }
    )

    if (!projectSaveResponse) {
      setIsLoading(BUTTON_STYLE.NONE)
    }
  }

  useEffect(() => {
    const availableCpus = Object.keys(TASK_CPU_LIST)
    const defaultSelectedCCredentials =
      !Boolean(cloud_credentials_id) && credentials?.length > 0
        ? credentials[0].id
        : null
    setCloudCredentialsId(cloud_credentials_id || defaultSelectedCCredentials)

    setTaskConfiguration({
      ...task_configuration,
      cpu: task_configuration?.cpu?.toString() || availableCpus[0],
      memory:
        task_configuration?.cpu && task_configuration?.memory
          ? task_configuration?.memory.toString()
          : TASK_MEMORY_LIST[availableCpus[0]][0].toString(),
      autoscaling_enabled:
        Boolean(task_configuration?.autoscaling_enabled) || false,
      min_count:
        task_configuration?.min_count ||
        (!Boolean(task_configuration?.autoscaling_enabled)
          ? null
          : DEFAULT_MIN_COUNT),
      max_count:
        task_configuration?.max_count ||
        (!Boolean(task_configuration?.max_count) ? null : DEFAULT_MAX_COUNT),
      desired_count:
        task_configuration?.desired_count ||
        (Boolean(task_configuration?.desired_count)
          ? null
          : DEFAULT_DESIRED_COUNT),
      autoscaling_cpu_threshold:
        task_configuration?.autoscaling_cpu_threshold ||
        (!Boolean(task_configuration?.autoscaling_cpu_threshold)
          ? null
          : DEFAULT_CPU_THRESHOLD),
      autoscaling_memory_threshold:
        task_configuration?.autoscaling_memory_threshold ||
        (!Boolean(task_configuration?.autoscaling_memory_threshold)
          ? null
          : DEFAULT_MEMORY_THRESHOLD),
      cloudwatch_enabled:
        Boolean(task_configuration?.cloudwatch_enabled) || false,
    })
  }, [task_configuration, cloud_credentials_id, credentials])

  if (!taskConfiguration) {
    return null
  }

  return (
    <form action="#" method="POST">
      {/* Configuration */}
      <div className="bg-white shadow sm:rounded-md mb-2">
        <div className="py-6 px-4 space-y-6 sm:p-6">
          {title}

          {/* Content */}
          <>
            <div className="sm:border-t sm:border-gray-200 sm:pt-5">
              <FormRow
                contentSpan={3}
                label={<FormLabel htmlFor="cpu">CPU</FormLabel>}
              >
                <TaskSizePicker
                  variant={TASK_SIZE_VARIANT.CPU}
                  values={Object.keys(TASK_CPU_LIST)}
                  selectedValue={taskConfiguration.cpu}
                  onChange={(value) => {
                    setTaskConfiguration((t) => ({
                      ...t,
                      cpu: value,
                      memory: TASK_MEMORY_LIST[value][0].toString(),
                    }))
                  }}
                />
              </FormRow>
            </div>

            <div className="sm:border-t sm:border-gray-200 sm:pt-5">
              <FormRow
                contentSpan={3}
                label={<FormLabel htmlFor="ram">Memory (RAM)</FormLabel>}
              >
                <TaskSizePicker
                  variant={TASK_SIZE_VARIANT.MEMORY}
                  values={TASK_MEMORY_LIST[taskConfiguration.cpu]}
                  selectedValue={taskConfiguration.memory}
                  onChange={(value) => {
                    setTaskConfiguration((t) => ({
                      ...t,
                      memory: value,
                    }))
                  }}
                />
              </FormRow>
            </div>

            <div className="sm:border-t sm:border-gray-200 sm:pt-5">
              <FormRow
                contentSpan={1}
                label={
                  <FormLabel htmlFor="autoscaling_enabled">
                    Autoscaling
                  </FormLabel>
                }
              >
                <div className="flex items-center">
                  <Toggle
                    enabled={taskConfiguration.autoscaling_enabled}
                    className="mt-1.5"
                    onChange={(value) => {
                      setTaskConfiguration((t) => ({
                        ...t,
                        autoscaling_enabled: value,
                        min_count: !value
                          ? null
                          : t.min_count || DEFAULT_MIN_COUNT,
                        max_count: !value
                          ? null
                          : t.max_count || DEFAULT_MAX_COUNT,
                        desired_count: value
                          ? null
                          : t.desired_count || DEFAULT_DESIRED_COUNT,
                        autoscaling_cpu_threshold: !value
                          ? null
                          : t.autoscaling_cpu_threshold ||
                          DEFAULT_CPU_THRESHOLD,
                        autoscaling_memory_threshold: !value
                          ? null
                          : t.autoscaling_memory_threshold ||
                          DEFAULT_MEMORY_THRESHOLD,
                      }))
                    }}
                  />
                  <FormLabel
                    className="ml-2 text-sm font-medium"
                    textColor={
                      taskConfiguration.autoscaling_enabled
                        ? 'text-blue-600'
                        : 'text-gray-400'
                    }
                  >
                    {taskConfiguration.autoscaling_enabled
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                </div>
              </FormRow>
            </div>

            {taskConfiguration.autoscaling_enabled ? (
              <>
                <FormRow contentSpan={3}>
                  <Grid>
                    <Col span={2}>
                      <FormLabel htmlFor="min_count" noPad>
                        Minimum # of replicas
                      </FormLabel>
                      <Input
                        type="number"
                        name="min_count"
                        id="min_count"
                        className="mt-1 w-32"
                        value={taskConfiguration.min_count}
                        min={1}
                        max={10}
                        onChange={(e) => {
                          setTaskConfiguration((t) => ({
                            ...t,
                            min_count: e.target.value,
                          }))
                        }}
                      />
                    </Col>
                    <Col span={2}>
                      <FormLabel htmlFor="max_count" noPad>
                        Maximum # of replicas
                      </FormLabel>
                      <Input
                        type="number"
                        name="max_count"
                        id="max_count"
                        className="mt-1 w-32"
                        value={taskConfiguration.max_count}
                        min={1}
                        max={10}
                        onChange={(e) => {
                          setTaskConfiguration((t) => ({
                            ...t,
                            max_count: e.target.value,
                          }))
                        }}
                      />
                    </Col>
                  </Grid>
                </FormRow>

                <FormRow contentSpan={3}>
                  <Grid>
                    <Col span={2}>
                      <FormLabel htmlFor="autoscaling_cpu_threshold" noPad>
                        CPU threshold
                      </FormLabel>
                      <div className="mt-1 relative">
                        <Input
                          type="number"
                          name="autoscaling_cpu_threshold"
                          id="autoscaling_cpu_threshold"
                          className="w-32 pl-6"
                          value={taskConfiguration.autoscaling_cpu_threshold}
                          min={1}
                          max={100}
                          onChange={(e) => {
                            setTaskConfiguration((t) => ({
                              ...t,
                              autoscaling_cpu_threshold: e.target.value,
                            }))
                          }}
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">%</span>
                        </div>
                      </div>
                    </Col>
                    <Col span={2}>
                      <FormLabel htmlFor="autoscaling_memory_threshold" noPad>
                        Memory threshold
                      </FormLabel>
                      <div className="mt-1 relative">
                        <Input
                          type="number"
                          name="autoscaling_memory_threshold"
                          id="autoscaling_memory_threshold"
                          className="w-32 pl-6"
                          value={taskConfiguration.autoscaling_memory_threshold}
                          min={1}
                          max={100}
                          onChange={(e) => {
                            setTaskConfiguration((t) => ({
                              ...t,
                              autoscaling_memory_threshold: e.target.value,
                            }))
                          }}
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">%</span>
                        </div>
                      </div>
                    </Col>
                  </Grid>
                </FormRow>
              </>
            ) : (
              <FormRow contentSpan={1}>
                <FormLabel htmlFor="desired_count" noPad>
                  Number of server replicas
                </FormLabel>
                <Input
                  type="number"
                  name="desired_count"
                  id="desired_count"
                  className="mt-1 w-32"
                  value={taskConfiguration.desired_count}
                  min={1}
                  max={10}
                  onChange={(e) => {
                    setTaskConfiguration((t) => ({
                      ...t,
                      desired_count: e.target.value,
                    }))
                  }}
                />
              </FormRow>
            )}

            <div className="sm:border-t sm:border-gray-200 sm:pt-5">
              <FormRow>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <Input
                      type="checkbox"
                      id="cloudwatch_enabled"
                      aria-describedby="cloudwatch_enabled-description"
                      name="cloudwatch_enabled"
                      checked={taskConfiguration.cloudwatch_enabled}
                      onChange={(e) => {
                        setTaskConfiguration((t) => ({
                          ...t,
                          cloudwatch_enabled: Boolean(!t.cloudwatch_enabled),
                        }))
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <FormLabel htmlFor="cloudwatch_enabled" noPad>
                      Enable CloudWatch logging
                    </FormLabel>
                  </div>
                </div>
              </FormRow>
            </div>
          </>
        </div>
      </div>

      {/* AWS Access */}
      <div className="bg-white shadow sm:rounded-md mb-2">
        <div className="py-6 px-4 space-y-6 sm:p-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                AWS Access
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Your credentials will be used to create the cloud resources
              </p>
            </div>
          </div>

          {/* Content */}
          <>
            <div className="sm:border-t sm:border-gray-200 sm:pt-5">
              <FormRow
                label={
                  <FormLabel htmlFor="cloud_credentials">
                    Select your cloud credentials
                  </FormLabel>
                }
              >
                {!isFetchingCredentials && successFetchingCredentials && (
                  <>
                    {cloudCredentials?.length > 0 ? (
                      <div className="w-full inline-flex items-center">
                        <SelectDropdown
                          values={cloudCredentials}
                          selectedValue={cloudCredentialsId}
                          disabled={Boolean(deployed_runs_exists)}
                          onChange={(value) => setCloudCredentialsId(value)}
                        />
                        {!Boolean(deployed_runs_exists) && (
                          <SecondaryButton
                            className="ml-2"
                            onClick={refetchCloudCredentials} // TODO: Make sure the toggle stays ON
                          >
                            <RefreshIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Refresh
                          </SecondaryButton>
                        )}
                        <a
                          href={formatUrl(
                            ROUTES.ORGANIZATION_SETTINGS.ACCESS_AWS,
                            organizationSlug
                          )}
                          className="text-blue-500 hover:text-blue-500 text-sm font-medium ml-4"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Manage
                        </a>
                      </div>
                    ) : (
                      <div className="inline-flex">
                        <Link
                          target={'_blank'}
                          to={formatUrl(
                            ROUTES.ORGANIZATION_SETTINGS.ACCESS_AWS,
                            organizationSlug
                          )}
                        >
                          <PrimaryButton>
                            <PlusIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Create
                          </PrimaryButton>
                        </Link>
                        <SecondaryButton
                          className="ml-2"
                          onClick={refetchCloudCredentials} // TODO: Make sure the toggle stays ON
                        >
                          <RefreshIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          Refresh
                        </SecondaryButton>
                      </div>
                    )}
                  </>
                )}
              </FormRow>
            </div>
          </>
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-md">
        <div className="flex items-center justify-end px-4 py-3 text-right sm:px-6">
          <SecondaryButton
            onClick={goToPrevious}
            isLoading={isLoading === BUTTON_STYLE.SECONDARY}
            disabled={isLoading !== BUTTON_STYLE.NONE}
          >
            Previous
          </SecondaryButton>
          <PrimaryButton
            className="ml-2"
            onClick={goToNext}
            isLoading={isLoading === BUTTON_STYLE.PRIMARY}
            disabled={isLoading !== BUTTON_STYLE.NONE}
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    </form>
  )
}
