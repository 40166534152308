import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'
import { classNames, formatUrl, timeAgo } from '../../helpers/functions'
import { ROUTES } from '../../helpers/constants'
import UserContext from '../../context/UserContext'
import ProjectStatusBadge from '../Badges/ProjectStatusBadge'

export default function ProjectsList({ projects }) {
  const {
    userContext: { selectedOrg },
  } = useContext(UserContext)

  if (!selectedOrg) {
    return null
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {projects.map((project) => (
          <li key={project.id}>
            <Link
              to={formatUrl(
                ROUTES.PROJECT_DETAILS,
                selectedOrg?.slug,
                project.slug
              )}
              className="block hover:bg-gray-50"
            >
              <div
                className={classNames(
                  'px-4 py-4 flex items-center sm:px-6',
                  `border-l-4 border-${project.color}-500`
                )}
              >
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm">
                      {/* <div
                        className="flex-shrink-0 w-2.5 h-2.5 rounded-full mr-1.5 bg-pink-600"
                        aria-hidden="true"
                      ></div> */}
                      <p className="font-medium text-blue-600 truncate">
                        {project.name}
                      </p>
                      {project?.domain_configuration?.domain_name && (
                        <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                          {project?.domain_configuration?.domain_name}
                        </p>
                      )}
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <span className="mr-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                          {project.stack?.name}
                        </span>
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>Updated {timeAgo(project.updated_at)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <ProjectStatusBadge project={project} />
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
