import ProjectStackEnum from '../../../enums/ProjectStackEnum'
import ECSConfiguration from './ECSConfiguration'
import ProjectConfigureContext from '../../../context/ProjectConfigureContext'
import StaticWebsiteConfiguration from './StaticWebsiteConfiguration'
import { useContext } from 'react'

export default function Configuration(commonProps) {
  const { project } = useContext(ProjectConfigureContext)
  const stack = project?.stack?.code

  const ConfigurationStackComponent = () => {
    const title = (
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Configuration
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          This information will be used to create your cloud resources
        </p>
      </div>
    )

    if (stack === ProjectStackEnum.ECS) {
      return <ECSConfiguration title={title} {...commonProps} />
    } else {
      return <StaticWebsiteConfiguration title={title} {...commonProps} />
    }
  }

  return <ConfigurationStackComponent />
}
