import { RadioGroup } from '@headlessui/react'
import { classNames } from '../../helpers/functions'
import { COLOR } from '../../helpers/constants'

const colors = [COLOR.PINK, COLOR.PURPLE, COLOR.BLUE, COLOR.GREEN, COLOR.YELLOW]

export default function ColorPicker({ selectedColor, onChange }) {
  // const [selectedColor, setSelectedColor] = useState(defaultColor)

  return (
    <RadioGroup
      value={selectedColor}
      onChange={(e) => {
        // setSelectedColor(e)
        onChange(e)
      }}
    >
      <div className="flex items-center space-x-3">
        {colors.map((color) => (
          <RadioGroup.Option
            key={color}
            value={color}
            className={({ checked }) =>
              classNames(
                checked && `ring-2 ring-${color}-500`,
                '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
              )
            }
          >
            <RadioGroup.Label as="span" className="sr-only">
              {color}
            </RadioGroup.Label>
            <span
              aria-hidden="true"
              className={classNames(
                `bg-${color}-500`,
                'h-8 w-8 border border-black border-opacity-10 rounded-full'
              )}
            />
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
