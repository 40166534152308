import { LockClosedIcon } from '@heroicons/react/solid'
import { classNames } from '../../helpers/functions'

export default function Input(props) {
  const {
    type = 'text',
    name,
    id,
    className,
    placeholder,
    value,
    disabled,
    onChange = () => null,
  } = props

  const styles = () => {
    switch (type) {
      case 'text':
      case 'number':
      case 'password':
        return 'w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md'
      case 'checkbox':
        return 'focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded hover:cursor-pointer'
      default:
        return ''
    }
  }

  return (
    <span className="relative">
      <input
        {...props}
        type={type}
        name={name}
        id={id}
        className={classNames(styles(), className)}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      {disabled && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <LockClosedIcon
            className="h-4 w-4 text-gray-300"
            aria-hidden="true"
          />
        </span>
      )}
    </span>
  )
}
