import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { classNames } from '../../helpers/functions'
import ProjectStackEnum from '../../enums/ProjectStackEnum'
import Badge from '../Badge'
import { COLOR } from '../../helpers/constants'

export default function StackSelector({
  stacks,
  selectedStack,
  onChange,
  readOnly = false,
}) {
  return (
    <RadioGroup
      value={selectedStack}
      onChange={(stack_id) => {
        if (!readOnly) {
          onChange(stack_id)
        }
      }}
    >
      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {stacks.map((stack) => (
          <RadioGroup.Option
            key={stack.id}
            value={stack.id}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                !checked && readOnly ? 'opacity-75' : '',
                active && !readOnly
                  ? 'border-blue-500 ring-2 ring-blue-500'
                  : '',
                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex-1 flex">
                  <div className="flex flex-col">
                    <div className="inline-flex items-center">
                      <img
                        alt={stack.description}
                        src={`/${stack.code}.svg`}
                        height="24"
                        width="24"
                        className="mr-1.5"
                      />
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {stack.name}
                      </RadioGroup.Label>
                    </div>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex-1 flex items-top text-sm text-gray-500"
                    >
                      {stack.description}
                    </RadioGroup.Description>
                    {/* <RadioGroup.Description
                      as="span"
                      className="mt-6 text-sm font-medium text-gray-900"
                    >
                      <span className="text-gray-500">Price:</span>{' '}
                      <span className="font-medium text-blue-800">
                        ${stack.price}
                      </span>
                    </RadioGroup.Description> */}
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-blue-600'
                  )}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active && !readOnly ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
