import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { isInMaintenanceMode } from '../helpers/functions'
import UserContext from '../context/UserContext'
import NotificationContext from '../context/NotificationContext'
import {
  defaultUserContext,
  notificationPropsDefault,
} from '../helpers/defaults'
import { ROUTES, PUBLIC_ROUTES } from '../helpers/constants'
import AuthenticationService from '../services/AuthenticationService'
import Notification from './Notification'
import Maintenance from './Maintenance'

export default function App({ children }) {
  const authenticationService = AuthenticationService.get()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [userContext, setUserContext] = useState(defaultUserContext)
  const [notificationProps, setNotificationProps] = useState(
    notificationPropsDefault
  )

  const showNotification = (type, title, subtitle = null, timeoutMs = 3000) => {
    setNotificationProps({ open: true, type, title, subtitle })
    if (timeoutMs) {
      setTimeout(() => {
        setNotificationProps((n) => ({ ...n, open: false }))
      }, timeoutMs)
    }
  }

  useEffect(() => {
    const token = authenticationService.getToken()
    if (!token && !pathname.includes(PUBLIC_ROUTES)) {
      navigate(ROUTES.LOGIN)
    } else if (token) {
      setUserContext((u) => ({ ...u, token }))
    }
  }, [pathname, authenticationService, navigate])

  return (
    <UserContext.Provider value={{ userContext, setUserContext }}>
      <NotificationContext.Provider value={{ showNotification }}>
        <div className="wrapper h-full">
          <>
            {isInMaintenanceMode ? <Maintenance /> : children}
            <Notification {...notificationProps} />
          </>
        </div>
      </NotificationContext.Provider>
    </UserContext.Provider>
  )
}
