import { useState, useContext } from 'react'
import OrganizationService from '../../../services/OrganizationService'
import NotificationTypeEnum from '../../../enums/NotificationTypeEnum'
import { COLOR } from '../../../helpers/constants'
import locale from '../../../translations/en'
import NotificationContext from '../../../context/NotificationContext'
import Slideout from '../../_shared/Slideout'
import FormRow from '../../Form/FormRow'
import FormLabel from '../../Form/FormLabel'
import Input from '../../_shared/Input'
import Alert from '../../_shared/Alert'
import SCMProviderEnum from '../../../enums/SCMProviderEnum'

export default function CreateGithubAccessSlideout({
  open,
  onClose,
  organizationId,
  onCreate,
}) {
  const organizationService = OrganizationService.get()
  const { showNotification } = useContext(NotificationContext)

  const [isLoading, setIsLoading] = useState(false)
  const [request, setRequest] = useState({
    name: '',
    provider: SCMProviderEnum.Github,
    pat: '',
  })

  const createSourceControlCredentials = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const response = await organizationService
      .createSCMCredentials(organizationId, request)
      .catch((e) => {
        showNotification(
          NotificationTypeEnum.Error,
          e?.response?.data?.message,
          null,
          5000
        )
      })

    if (response?.success) {
      showNotification(
        NotificationTypeEnum.Success,
        locale.sourceControlCredentials.successCreate,
        null,
        5000
      )

      onCreate()

      return
    }

    setIsLoading(false)
  }

  return (
    <Slideout
      open={open}
      onClose={onClose}
      title="New GitHub Credentials"
      subtitle="Get started by filling in the information below to set up new GitHub credentials for your organization."
      onSubmit={createSourceControlCredentials}
      isLoading={isLoading}
    >
      <Alert
        color={COLOR.BLUE}
        title="Instructions"
        subtitle={
          <ul className="list-disc ml-4">
            <li>
              Navigate to the{' '}
              <a
                href="https://github.com/settings/tokens"
                target="_blank"
                className="underline hover:no-underline"
              >
                GitHub Personal Access Tokens
              </a>{' '}
              page
            </li>
            <li>Create a new Token (classic)</li>
            <li>
              Include the following scopes:{' '}
              <span className="font-mono font-bold">admin:org, repo</span>
            </li>
          </ul>
        }
        className="m-6"
      />

      <div className="py-6 px-4 space-y-6 sm:p-6">
        <div>
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="name">Name</FormLabel>}
          >
            <Input
              name="name"
              id="name"
              placeholder="My Business Inc."
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  name: e.target.value,
                }))
              }
              value={request.name}
              required
            />
          </FormRow>
        </div>

        <div className="sm:border-t sm:border-gray-200 sm:pt-5">
          <FormRow
            contentSpan={4}
            label={<FormLabel htmlFor="pat">Personal Access Token</FormLabel>}
          >
            <Input
              name="pat"
              id="pat"
              placeholder=""
              onChange={(e) =>
                setRequest((r) => ({
                  ...r,
                  pat: e.target.value,
                }))
              }
              value={request.pat}
              required
            />
          </FormRow>
        </div>
      </div>
    </Slideout>
  )
}
