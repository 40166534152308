import {
  ChartSquareBarIcon,
  OfficeBuildingIcon,
  StarIcon,
} from '@heroicons/react/outline'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../helpers/constants'
import useGetDashboard from '../../hooks/useGetDashboard'

export default function HomeWidgets() {
  const { isLoading, data: dashboard } = useGetDashboard()

  const widgets = useMemo(
    () => [
      {
        id: 1,
        name: 'Organizations',
        stat: (
          <p className="text-2xl font-semibold text-gray-900">
            {dashboard?.data?.organizations}
          </p>
        ),
        route: ROUTES.ORGANIZATIONS,
        icon: OfficeBuildingIcon,
      },
      {
        id: 2,
        name: 'Projects',
        stat: (
          <p className="text-2xl font-semibold text-gray-900">
            {dashboard?.data?.projects}
          </p>
        ),
        route: ROUTES.PROJECTS,
        icon: ChartSquareBarIcon,
      },
      {
        id: 3,
        name: 'Favorite Projects',
        stat: <p className="mt-2 text-sm text-gray-500">No favorites yet</p>,
        icon: StarIcon,
      },
    ],
    [dashboard?.data]
  )

  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      {widgets.map((item) => (
        <div
          key={item.id}
          className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
        >
          <dt>
            <div className="absolute rounded-md bg-blue-500 p-3">
              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              {item.name}
            </p>
          </dt>
          <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
            {item.stat}
            {Boolean(item.route) && (
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <Link
                    to={item.route}
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    View all<span className="sr-only"> {item.name} stats</span>
                  </Link>
                </div>
              </div>
            )}
          </dd>
        </div>
      ))}
    </dl>
  )
}
