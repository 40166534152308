import HomeWidgets from '../components/Home/HomeWidgets'
import Layout from '../components/Layout'
import useGetUser from '../hooks/useGetUser'

export default function Home() {
  const { isLoading, data: user } = useGetUser()

  return (
    <Layout title={`Welcome, ${user?.data?.name}!`}>
      <HomeWidgets />
    </Layout>
  )
}
