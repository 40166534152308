import { Switch } from '@headlessui/react'
import Toggle from '../../Toggle'

export default function SCMOrganizationToggle({
  organization,
  onEnabledOrgsUpdated,
}) {
  const onChange = (value) => {
    onEnabledOrgsUpdated({ ...organization, enabled: Boolean(value) })
  }

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <div className="flex items-center">
          <img
            src={organization.avatar_url}
            alt={organization.name}
            className="w-6 h-6 mr-2"
          />
          <Switch.Label
            as="span"
            className="text-sm font-medium text-gray-900"
            passive
          >
            {organization.name}
          </Switch.Label>
        </div>
      </span>
      <Toggle enabled={organization.enabled} onChange={onChange} />
    </Switch.Group>
  )
}
