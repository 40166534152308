import { CollectionIcon, UsersIcon } from '@heroicons/react/solid'
import { useParams } from 'react-router-dom'
import useGetOrganization from '../../hooks/useGetOrganization'
import LoadingCard from '../LoadingCard'

export default function OrganizationDetails() {
  const { organizationSlug } = useParams()
  const { isLoading, data: organizationDetails } =
    useGetOrganization(organizationSlug)

  if (isLoading || !organizationDetails) {
    return <LoadingCard />
  }

  return (
    <section aria-labelledby="project-overview">
      <div className="bg-white shadow sm:rounded-md">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="project-overview"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Details
          </h2>
          {organizationDetails.description && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {organizationDetails.description}
            </p>
          )}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {organizationDetails.name}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Identifier</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {organizationDetails.slug}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Members</dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <UsersIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {organizationDetails.members_count}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Projects</dt>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <CollectionIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {organizationDetails.projects_count}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  )
}
