import { PlusIcon } from '@heroicons/react/outline'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BUTTON_SIZE, COLOR, ROUTES } from '../../../helpers/constants'
import { formatUrl } from '../../../helpers/functions'
import useGetOrganization from '../../../hooks/useGetOrganization'
import useListCloudCredentials from '../../../hooks/useListCloudCredentials'
import { PrimaryButton } from '../../Form/Button'
import FormRow from '../../Form/FormRow'
import Heading from '../../Heading'
import Layout from '../../Layout'
import Card from '../../Layout/Card'
import { Grid, Col } from '../../Layout/Grid'
import CreateAWSAccessSlideout from './CreateAWSAccessSlideout'
import OrganizationSettingsNav from './OrganizationSettingsNav'

export default function OrganizationSettingsAccessAWS() {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const { organizationSlug } = useParams()
  const [organizationId, setOrganizationId] = useState(null)
  const [cloudCredentials, setCloudCredentials] = useState(null)
  const { isLoading, data: organizationDetails } =
    useGetOrganization(organizationSlug)
  const { data: cloudCredentialsResponse, refetch: refetchCloudCredentials } =
    useListCloudCredentials(organizationId)

  const onCreate = () => {
    setIsFormOpen(false)
    refetchCloudCredentials()
  }

  useEffect(() => {
    if (!organizationDetails) {
      return
    }

    setOrganizationId(organizationDetails?.id)
  }, [organizationDetails])

  useEffect(() => {
    if (cloudCredentialsResponse?.success) {
      setCloudCredentials(cloudCredentialsResponse?.data)
    }
  }, [cloudCredentialsResponse])

  const breadcrumbs = [
    {
      name: organizationDetails?.name,
      path: formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, organizationSlug),
    },
    {
      name: 'Settings',
    },
  ]

  return (
    <Layout
      title={organizationDetails?.name}
      breadcrumbs={breadcrumbs}
      nav={
        <OrganizationSettingsNav
          organizationSlug={organizationSlug}
          currentRoute={ROUTES.ORGANIZATION_SETTINGS.ACCESS_AWS}
        />
      }
    >
      <Card
        header={
          <Grid cols={2}>
            <Col span={1} className="flex items-center">
              <div>
                <Heading size={3}>AWS Access</Heading>
                <p className="text-sm text-gray-500">
                  Set up access to your AWS account
                </p>
              </div>
            </Col>
            <Col span={1} className="text-right">
              <PrimaryButton
                onClick={() => {
                  setIsFormOpen(true)
                }}
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Add
              </PrimaryButton>
            </Col>
          </Grid>
        }
      >
        {cloudCredentials?.length > 0 ? (
          <div className="space-y-6">
            <Grid>
              {cloudCredentials.map((credential) => (
                <Fragment key={credential.id}>
                  <Col span={4}>
                    <div className="text-sm font-medium text-gray-900">
                      {credential.name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {credential.key_preview}
                    </div>
                  </Col>
                  <Col span={2} className="text-right">
                    <PrimaryButton
                      size={BUTTON_SIZE.SMALL}
                      color={COLOR.RED}
                      disabled
                    >
                      Delete
                    </PrimaryButton>
                  </Col>
                </Fragment>
              ))}
            </Grid>
          </div>
        ) : (
          <div className="text-sm text-center text-gray-500">
            No credentials found
          </div>
        )}
      </Card>
      <CreateAWSAccessSlideout
        open={isFormOpen}
        onClose={() => {
          setIsFormOpen(false)
        }}
        organizationId={organizationId}
        onCreate={onCreate}
      />
    </Layout>
  )
}
