export default class Singleton {
  private static instance: any
  protected constructor() {}

  public static get(Service = this) {
    if (!this.instance) {
      this.instance = new Service()
    }

    return this.instance
  }
}
