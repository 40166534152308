import Singleton from './Singleton'
import HttpService from './HttpService'
import IHttpResponse from '../interfaces/IHttpResponse'

export default class MetadataService extends Singleton {
  private constructor() {
    super()
  }

  getMetadata = async (): Promise<IHttpResponse> =>
    HttpService.get().getMetadata()
}
