import { useEffect, useState } from 'react'
import { BADGE_TYPE, COLOR } from '../../helpers/constants'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import ProjectRunStateEnum from '../../enums/ProjectRunStateEnum'
import ProjectRunEndReasonEnum from '../../enums/ProjectRunEndReasonEnum'
import Badge from '../Badge'

export default function ProjectStatusBadge({ project }) {
  const [badgeType, setBadgeType] = useState(null)
  const [badgeColor, setBadgeColor] = useState()

  useEffect(() => {
    const state = project?.latest_run?.state
    const endReason = project?.latest_run?.end_reason
    const isDeployed = project?.deployed_runs_exists

    if (
      state === ProjectRunStateEnum.InProgress ||
      state === ProjectRunStateEnum.Queued
    ) {
      setBadgeType(BADGE_TYPE.DEPLOYING)
      setBadgeColor(COLOR.YELLOW)
      return
    }

    if (isDeployed) {
      if (state === ProjectRunStateEnum.Finished) {
        setBadgeType(BADGE_TYPE.DEPLOYED)
      } else {
        // TODO: Handle error
        setBadgeType(BADGE_TYPE.MODIFIED)
      }

      setBadgeColor(COLOR.GREEN)
      return
    } else {
      if (state === ProjectRunStateEnum.Finished) {
        if (endReason === ProjectRunEndReasonEnum.Error) {
          setBadgeType(BADGE_TYPE.FAILED)
          setBadgeColor(COLOR.RED)
          return
        }
      }
    }

    setBadgeType(BADGE_TYPE.DRAFT)
    setBadgeColor(COLOR.GRAY)
  }, [project])

  if (!badgeType || !badgeColor) {
    return null
  }

  return (
    <Badge color={badgeColor}>
      {badgeType === BADGE_TYPE.DEPLOYING && (
        <span className="flex relative h-3 w-3 -ml-1 mr-1.5">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
        </span>
      )}
      {badgeType === BADGE_TYPE.DRAFT && (
        <svg
          className="-ml-0.5 mr-1.5 h-2 w-2 text-gray-400"
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
      )}
      {badgeType === BADGE_TYPE.DEPLOYED && (
        <CheckCircleIcon
          className="h-4 w-4 text-green-500 -ml-1.5 mr-1"
          aria-hidden="true"
        />
      )}
      {badgeType === BADGE_TYPE.FAILED && (
        <ExclamationCircleIcon
          className="h-4 w-4 text-red-500 -ml-1.5 mr-1"
          aria-hidden="true"
        />
      )}
      {badgeType}
    </Badge>
  )
}
