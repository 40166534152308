import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/constants'
import useGetUser from '../../../hooks/useGetUser'
import { PrimaryButton } from '../../Form/Button'
import FormRow from '../../Form/FormRow'
import Heading from '../../Heading'
import Input from '../../_shared/Input'
import Layout from '../../Layout'
import Card from '../../Layout/Card'
import UserSettingsNav from './UserSettingsNav'
import FormLabel from '../../Form/FormLabel'

export default function UserSettingsGeneral() {
  const { organizationSlug } = useParams()
  const { isLoading, data } = useGetUser()
  const { success, data: userDetails, message } = data || {}
  const [request, setRequest] = useState({
    name: '',
    email: '',
  })

  useEffect(() => {
    if (!success || !userDetails) {
      return
    }

    setRequest({
      name: userDetails?.name,
      email: userDetails?.email,
    })
  }, [userDetails])

  const breadcrumbs = [
    {
      name: 'Profile',
    },
    {
      name: 'Settings',
    },
  ]

  return (
    <Layout
      title="Profile"
      breadcrumbs={breadcrumbs}
      nav={
        <UserSettingsNav
          organizationSlug={organizationSlug}
          currentRoute={ROUTES.USER_SETTINGS.GENERAL}
        />
      }
    >
      <form onSubmit={() => {}}>
        <Card
          header={<Heading size={3}>General</Heading>}
          footer={
            <div className="text-right">
              <PrimaryButton disabled>Save</PrimaryButton>
            </div>
          }
        >
          <div className="space-y-6">
            <FormRow label={<FormLabel htmlFor="name">Name</FormLabel>}>
              <Input
                name="name"
                id="name"
                placeholder="Name"
                onChange={(e) =>
                  setRequest((r) => ({
                    ...r,
                    name: e.target.value,
                  }))
                }
                value={request.name}
                disabled
                required
              />
            </FormRow>

            <FormRow label={<FormLabel htmlFor="slug">Email</FormLabel>}>
              <Input name="slug" id="slug" value={request.email} disabled />
            </FormRow>
          </div>
        </Card>
      </form>
    </Layout>
  )
}
