import RunEventTypeEnum from '../enums/RunEventTypeEnum'

const locale = {
  general: {
    success: 'Success!',
    copiedToClipboard: 'Text successfully copied to clipboard!',
  },
  login: {
    successLogin: 'Successfully logged in!',
    errorLogin: 'Failed to log in',
    errorLoginSubtitle: 'Please check your credentials and try again',
  },
  logout: {
    success: 'You have successfully been logged out!',
  },
  project: {
    successCreate: 'Project successfully created!',
    successCreateSubtitle: 'You can now configure it to your liking',
    errorUpdate: 'Failed to update',
    errorUpdateRequiredFields: 'Please fill out all of the required fields',
    errorDeploy: 'Failed to deploy',
    errorCheckoutSession: 'Failed to initiate checkout flow',
    errorCreate: 'Failed to create project',
  },
  organization: {
    successCreate: 'Organization successfully created!',
    successCreateSubtitle: 'You can now create projects',
    errorCreate: 'Failed to create organization',
  },
  cloudCredentials: {
    successCreate: 'AWS credentials successfully created!',
  },
  sourceControlCredentials: {
    successCreate: 'GitHub credentials successfully created!',
  },
  changePassword: {
    success: 'Password successfully changed!',
  },
  runEventType: {
    [RunEventTypeEnum.Starting]: 'Starting the run',
    [RunEventTypeEnum.CreatingGitRepo]: 'Creating GitHub repository',
    [RunEventTypeEnum.PushingGitBase]: 'Pushing base repository to GitHub',
    [RunEventTypeEnum.CreatingCloudResources]: 'Creating Cloud Resources',
    [RunEventTypeEnum.UpdatingGitSecrets]: 'Updating GitHub Secrets',
    [RunEventTypeEnum.UpdatingGitTaskDefinition]:
      'Updating GitHub task definition',
    [RunEventTypeEnum.Finalizing]: 'Finalizing changes',
    [RunEventTypeEnum.Success]: 'Successfully applied changes',
    [RunEventTypeEnum.Error]: 'Failed to apply changes',
  },
}

export default locale
