import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { useContext, useEffect, useState } from 'react'
import ProjectConfigureContext from '../../../context/ProjectConfigureContext'
import AWSRegionEnum from '../../../enums/AWSRegionEnum'
import ProjectConfigureStepEnum from '../../../enums/ProjectConfigureStepEnum'
import ProjectStackEnum from '../../../enums/ProjectStackEnum'
import { AWS_REGIONS, BUTTON_STYLE, COLOR } from '../../../helpers/constants'
import { slugifyName } from '../../../helpers/functions'
import useGetMetadata from '../../../hooks/useGetMetadata'
import { PrimaryButton } from '../../Form/Button'
import Input from '../../_shared/Input'
import ColorPicker from '../../Projects/ColorPicker'
import StackSelector from '../../Projects/StackSelector'
import SelectDropdown from '../../SelectDropdown'
import FormRow from '../../Form/FormRow'
import FormLabel from '../../Form/FormLabel'

export default function General({ currentStep, onStepChange }) {
  const { project } = useContext(ProjectConfigureContext)
  const [isLoading, setIsLoading] = useState(BUTTON_STYLE.NONE)
  const [projectDetails, setProjectDetails] = useState(null)
  const { isLoading: isLoadingStacks, data: metadata } = useGetMetadata()
  const stacks = metadata?.data?.stacks || []

  const goToNext = async () => {
    setIsLoading(BUTTON_STYLE.PRIMARY)

    const projectSaveResponse = await onStepChange(
      ProjectConfigureStepEnum.Configuration,
      {
        name: projectDetails.name,
        slug: projectDetails.slug, // TODO: Once this is changed, redirect to new URL
        owner_name: projectDetails.owner_name,
        aws_region: projectDetails.aws_region,
        color: projectDetails.color,
        description: projectDetails.description,
        stack: projectDetails.stack,
      }
    )

    if (!projectSaveResponse) {
      setIsLoading(BUTTON_STYLE.NONE)
    }
  }

  useEffect(() => {
    setProjectDetails({
      ...project,
      name: project?.name || '',
      slug: project?.slug || '',
      owner_name: project?.owner_name || '',
      aws_region: project?.aws_region || AWSRegionEnum.USEast2,
      color: project?.color || COLOR.BLUE,
      description: project?.description || '',
    })
  }, [project])

  return (
    <form action="#" method="POST">
      {/* General */}
      <div className="bg-white shadow sm:rounded-md mb-2">
        <div className="py-6 px-4 space-y-6 sm:p-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                General
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                This information will be used to create your cloud resources
              </p>
            </div>
          </div>

          {/* Content */}
          {projectDetails && (
            <>
              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={<FormLabel htmlFor="name">Project name</FormLabel>}
                >
                  <Input
                    name="name"
                    id="name"
                    placeholder="My API Service"
                    value={projectDetails.name}
                    onChange={(e) => {
                      setProjectDetails((d) => ({
                        ...d,
                        name: e.target.value,
                      }))
                    }}
                    onBlur={() => {
                      if (projectDetails.slug.length === 0) {
                        setProjectDetails((d) => ({
                          ...d,
                          slug: slugifyName(d.name),
                        }))
                      }
                    }}
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="slug">Project identifier</FormLabel>
                  }
                >
                  <Input
                    name="slug"
                    id="slug"
                    className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="my-api-service"
                    value={projectDetails.slug}
                    disabled={Boolean(projectDetails.deployed_runs_exists)}
                    onChange={(e) => {
                      setProjectDetails((d) => ({
                        ...d,
                        slug: e.target.value,
                      }))
                    }}
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Unique URL identifier
                  </p>
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="owner_name">Project owner</FormLabel>
                  }
                >
                  <Input
                    name="owner_name"
                    id="owner_name"
                    className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="my-api-service"
                    value={projectDetails.owner_name}
                    disabled={Boolean(projectDetails.deployed_runs_exists)}
                    onChange={(e) => {
                      setProjectDetails((d) => ({
                        ...d,
                        owner_name: e.target.value,
                      }))
                    }}
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Your AWS resources will be tagged with this name
                  </p>
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={<FormLabel htmlFor="aws_region">AWS region</FormLabel>}
                >
                  <SelectDropdown
                    values={AWS_REGIONS}
                    selectedValue={projectDetails.aws_region}
                    disabled={Boolean(projectDetails.deployed_runs_exists)}
                    onChange={(value) =>
                      setProjectDetails((d) => ({
                        ...d,
                        aws_region: value,
                      }))
                    }
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="color">Color identifier</FormLabel>
                  }
                >
                  <ColorPicker
                    selectedColor={projectDetails.color}
                    onChange={(value) => {
                      setProjectDetails((d) => ({
                        ...d,
                        color: value,
                      }))
                    }}
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  label={
                    <FormLabel htmlFor="description">Description</FormLabel>
                  }
                >
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    onChange={(e) =>
                      setProjectDetails((d) => ({
                        ...d,
                        description: e.target.value,
                      }))
                    }
                    value={projectDetails.description}
                  />
                </FormRow>
              </div>

              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <FormRow
                  contentSpan={3}
                  label={
                    <FormLabel htmlFor="stack">Application type</FormLabel>
                  }
                >
                  {projectDetails.stack_id && (
                    <StackSelector
                      stacks={stacks}
                      selectedStack={projectDetails.stack_id}
                      readOnly
                    />
                  )}
                  <a
                    href="/"
                    className="group inline-flex mt-3 items-center text-sm text-gray-500 hover:text-gray-900"
                  >
                    <QuestionMarkCircleIcon
                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2">
                      Learn more about application types
                    </span>
                  </a>
                </FormRow>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-md">
        <div className="flex items-center justify-end px-4 py-3 text-right sm:px-6">
          <PrimaryButton
            className="ml-2"
            onClick={goToNext}
            isLoading={isLoading === BUTTON_STYLE.PRIMARY}
            disabled={isLoading !== BUTTON_STYLE.NONE}
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    </form>
  )
}
