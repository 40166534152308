/**
 * Translations for this enum are stored in /src/translations/en.js
 * Must map to the same enum on the API side
 */
enum RunEventTypeEnum {
  Starting = 'starting',
  CreatingGitRepo = 'creating_git_repo',
  PushingGitBase = 'pushing_git_base',
  CreatingCloudResources = 'creating_cloud_resources',
  UpdatingGitSecrets = 'updating_git_secrets',
  UpdatingGitTaskDefinition = 'updating_git_task_definition',
  Finalizing = 'finalizing',
  Success = 'success',
  Error = 'error',
}

export default RunEventTypeEnum
