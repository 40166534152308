import { useQuery } from 'react-query'
import { HOOK_KEY } from '../helpers/constants'
import ProjectService from '../services/ProjectService'

export default function useGetProject(
  organizationSlug,
  projectSlug,
  refetchInterval = null,
  pathname = null
) {
  const projectService = ProjectService.get()

  return useQuery(
    [HOOK_KEY.PROJECT, organizationSlug, projectSlug, pathname],
    () => projectService.getProject(organizationSlug, projectSlug),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: refetchInterval, // TODO: Enable once in Prod
      enabled: Boolean(organizationSlug) && Boolean(projectSlug),
    }
  )
}
