import Singleton from './Singleton'
import ILoginRequest from '../interfaces/ILoginRequest'
import HttpService from './HttpService'
import { HTTP_STATUS, ROUTES } from '../helpers/constants'

export default class AuthenticationService extends Singleton {
  private constructor() {
    super()
  }

  readonly LOCAL_STORAGE_AUTH_KEY = 'auth-token'

  getToken = () => localStorage.getItem(this.LOCAL_STORAGE_AUTH_KEY)

  setToken = (token: string) =>
    localStorage.setItem(this.LOCAL_STORAGE_AUTH_KEY, token)

  removeToken = () => localStorage.removeItem(this.LOCAL_STORAGE_AUTH_KEY)

  redirectToLogin() {
    window.location.href = ROUTES.LOGIN
  }

  async login(form: ILoginRequest) {
    const httpService = HttpService.get()

    // TODO: Validate

    const loginResponse = await httpService.postLogin(form)

    // TODO: Process Response

    return loginResponse?.data
  }

  async logout() {
    const httpService = HttpService.get()

    // TODO: Validate

    const loginResponse = await httpService.logout()

    // TODO: Process Response

    return loginResponse?.data
  }

  axiosRequestSuccessHandler = (config: any) => {
    if (this.getToken()) {
      config.headers.Authorization = `Bearer ${this.getToken()}`
    }

    return config
  }

  axiosRequestErrorHandler = (error: any) => Promise.reject(error)

  axiosResponseSuccessHandler = (response: any) => response

  axiosResponseErrorHandler = (error: any) => {
    // TODO: Make sure this isn't called from the login endpoint
    // TODO: Make sure 401s for wrong user permissions don't trigger this
    if (
      this.getToken() &&
      error?.response?.status === HTTP_STATUS.UNAUTHORIZED
    ) {
      this.removeToken()
      this.redirectToLogin()
      console.log('Removed token')
    }

    return Promise.reject(error)
  }
}
