import { useContext } from 'react'
import NotificationContext from '../../context/NotificationContext'
import NotificationTypeEnum from '../../enums/NotificationTypeEnum'
import {
  COLOR,
  DB_ENGINE_NAMES,
  ROUTES,
  TASK_CPU_LIST,
  TASK_MEMORY_NAMES,
} from '../../helpers/constants'
import { formatUrl } from '../../helpers/functions'
import githubLogo from '../../images/github-logo.svg'
import locale from '../../translations/en'
import Badge from '../Badge'
import ProjectStatusBadge from '../Badges/ProjectStatusBadge'
import CopyToClipboard from '../CopyToClipboard'
import LastRunTimeline from './LatestRunTimeline'

export default function ProjectDetails({ project }) {
  const { showNotification } = useContext(NotificationContext)

  const onCopySuccess = () =>
    showNotification(
      NotificationTypeEnum.Success,
      locale.general.copiedToClipboard,
      null,
      3000
    )

  const isRepoLinked =
    Boolean(project?.features?.create_repository) &&
    Boolean(project?.scm_configuration?.is_repo_linked) &&
    Boolean(project?.scm_configuration?.scm_organization?.name) &&
    Boolean(project?.scm_configuration?.repo_name)
  const isCustomDomainConfigured =
    Boolean(project?.features?.create_domain_record) &&
    Boolean(project?.domain_configuration?.domain_name)
  const isCustomDatabaseConfigured =
    Boolean(project?.features?.create_database) &&
    Boolean(project?.metadata?.rds_url)
  const customDomainName = isCustomDomainConfigured
    ? `${
        Boolean(project?.domain_configuration?.create_ssl)
          ? 'https://'
          : 'http://'
      }${project.domain_configuration.domain_name}`
    : null

  return (
    <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <div className="space-y-6 lg:col-start-1 lg:col-span-2">
        {/* Description list*/}
        <section aria-labelledby="project-overview">
          <div className="bg-white shadow sm:rounded-md">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="project-overview"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Overview
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Project details and management.
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">{project.name}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <ProjectStatusBadge project={project} />
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Identifier
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{project.slug}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Service Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {project.stack.name}
                  </dd>
                </div>
                {isRepoLinked && (
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      GitHub Repository
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 inline-flex items-center">
                      <img
                        className="mr-1.5 w-5 h-5"
                        src={githubLogo}
                        alt="GitHub"
                      />
                      <a
                        href={formatUrl(
                          ROUTES.EXTERNAL.GITHUB_REPO,
                          project?.scm_configuration?.scm_organization?.name,
                          project?.scm_configuration?.repo_name
                        )}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:text-blue-500 hover:cursor-pointer"
                      >
                        {project.scm_configuration.scm_organization.name}/
                        {project.scm_configuration.repo_name}
                      </a>
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </section>

        {/* Specifications */}
        <section aria-labelledby="project-specs">
          <div className="bg-white shadow sm:rounded-md sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="project-specs"
                  className="text-lg font-medium text-gray-900"
                >
                  Specifications
                </h2>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Performance
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <span className="flex items-center mb-2">
                        CPU:
                        <Badge color={COLOR.GRAY} className="text-xs ml-1">
                          {TASK_CPU_LIST[project.task_configuration.cpu]}
                        </Badge>
                      </span>
                      <span className="flex items-center">
                        RAM:
                        <Badge color={COLOR.GRAY} className="text-xs ml-1">
                          {TASK_MEMORY_NAMES[project.task_configuration.memory]}
                        </Badge>
                      </span>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Availability
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {Boolean(
                        project.task_configuration.autoscaling_enabled
                      ) ? (
                        <span className="flex items-center">
                          Server Replicas:
                          <Badge color={COLOR.GRAY} className="text-xs ml-1">
                            Min {project.task_configuration.min_count}
                          </Badge>
                          <Badge color={COLOR.GRAY} className="text-xs ml-1">
                            Max {project.task_configuration.max_count}
                          </Badge>
                        </span>
                      ) : (
                        <>
                          Server Replicas:{' '}
                          {project.task_configuration.desired_count}
                        </>
                      )}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Custom URL
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 inline-flex items-center">
                      {isCustomDomainConfigured ? (
                        <>
                          <CopyToClipboard
                            text={customDomainName}
                            onCopy={onCopySuccess}
                          />
                          <a
                            href={customDomainName}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500 hover:text-blue-500 hover:cursor-pointer"
                          >
                            {project.domain_configuration.domain_name}
                          </a>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Load Balancer URL
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 inline-flex items-center">
                      {Boolean(project?.metadata?.alb_url) ? (
                        <>
                          <CopyToClipboard
                            text={`http://${project.metadata.alb_url}`}
                            onCopy={onCopySuccess}
                          />
                          <a
                            href={`http://${project.metadata.alb_url}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500 hover:text-blue-500 hover:cursor-pointer"
                          >
                            {project.metadata.alb_url}
                          </a>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </section>

        {/* Database */}
        {Boolean(project.features.create_database) && (
          <section aria-labelledby="project-specs">
            <div className="bg-white shadow sm:rounded-md sm:overflow-hidden">
              <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="project-specs"
                    className="text-lg font-medium text-gray-900"
                  >
                    Database
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Database Configuration
                      </dt>
                      <dd className="flex items-center mt-1 text-sm text-gray-900">
                        {DB_ENGINE_NAMES[project.database_configuration.engine]}{' '}
                        {project.database_configuration.engine_version}
                        <Badge color={COLOR.GRAY} className="text-xs ml-1">
                          {project.database_configuration.instance_type}
                        </Badge>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Database Host URL
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 inline-flex items-center">
                        {isCustomDatabaseConfigured ? (
                          <>
                            <CopyToClipboard
                              text={project.metadata.rds_url}
                              onCopy={onCopySuccess}
                            />
                            <i>{project.metadata.rds_url}</i>
                          </>
                        ) : (
                          'N/A'
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Runs */}
        {/* <section aria-labelledby="project-runs">
          <div className="bg-white shadow sm:rounded-md sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="project-runs"
                  className="text-lg font-medium text-gray-900"
                >
                  Runs
                </h2>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                List of Runs
              </div>
            </div>
          </div>
        </section> */}
      </div>

      <LastRunTimeline project={project} />
    </div>
  )
}
