import { useQuery } from 'react-query'
import AuthenticationService from '../services/AuthenticationService'
import UserService from '../services/UserService'

export default function useGetDashboard() {
  const userService = UserService.get()
  const authenticationService = AuthenticationService.get()

  return useQuery(['dashboard'], () => userService.getDashboard(), {
    retry: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    enabled: Boolean(authenticationService.getToken()),
  })
}
