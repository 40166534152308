import { Link } from 'react-router-dom'
import { ROUTES } from '../../../helpers/constants'
import { classNames, formatUrl } from '../../../helpers/functions'

export default function OrganizationSettingsNav({
  organizationSlug,
  currentRoute,
}) {
  const navigation = [
    {
      name: 'General',
      path: ROUTES.ORGANIZATION_SETTINGS.GENERAL,
      icon: null,
    },
    { name: 'Users', path: ROUTES.ORGANIZATION_SETTINGS.USERS, icon: null },
    {
      name: 'GitHub Access',
      path: ROUTES.ORGANIZATION_SETTINGS.ACCESS_GITHUB,
      icon: null,
    },
    {
      name: 'AWS Access',
      path: ROUTES.ORGANIZATION_SETTINGS.ACCESS_AWS,
      icon: null,
    },
    // {
    //   name: 'Payment Methods',
    //   path: ROUTES.ORGANIZATION_SETTINGS.PAYMENT_METHODS,
    //   icon: null,
    // },
  ]

  return (
    <nav className="space-y-1" aria-label="Sidebar">
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={formatUrl(item.path, organizationSlug)}
          className={classNames(
            item.path === currentRoute
              ? 'bg-white text-blue-600 shadow-sm'
              : 'text-gray-600 hover:bg-white hover:text-blue-600',
            'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
          )}
          aria-current={item.path === currentRoute ? 'page' : undefined}
        >
          {/* <item.icon
                className={classNames(
                item.path === currentRoute
                    ? 'text-gray-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                )}
                aria-hidden="true"
            /> */}
          <span className="truncate">{item.name}</span>
        </Link>
      ))}
    </nav>
  )
}
