import { useQuery } from 'react-query'
import OrganizationService from '../services/OrganizationService'

export default function useListCloudCredentials(organizationId) {
  const organizationService = OrganizationService.get()

  return useQuery(
    ['cloud_credentials', organizationId],
    () => organizationService.getCloudCredentials(organizationId),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(organizationId),
    }
  )
}
