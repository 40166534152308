import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useGetProject from '../../hooks/useGetProject'
import { ROUTES } from '../../helpers/constants.ts'
import Layout from '../Layout'
import { formatUrl } from '../../helpers/functions'
import ProjectPreviewTable from './ProjectPreviewTable'

export default function ProjectPreviewContainer() {
  const location = useLocation()
  const { organizationSlug, projectSlug } = useParams()
  const [pageTitle, setPageTitle] = useState(null)
  const { isLoading: isFetchingProject, data: projectData } = useGetProject(
    organizationSlug,
    projectSlug,
    null,
    location.pathname
  )
  const [project, setProject] = useState(null)

  const breadcrumbs = project && [
    {
      name: project.organization?.name,
      path: formatUrl(
        ROUTES.ORGANIZATION_DETAILS.GENERAL,
        project.organization?.slug
      ),
    },
    {
      name: 'Projects',
      // TODO: Re-enable when Org->Projects section is completed
      // path: formatUrl(
      //   ROUTES.ORGANIZATION_DETAILS.PROJECTS,
      //   project.organization?.slug
      // ),
    },
    {
      name: project.name,
      path: formatUrl(
        ROUTES.PROJECT_DETAILS,
        project.organization?.slug,
        project.slug
      ),
    },
    {
      name: 'Preview',
    },
  ]

  // Sets page title
  // Auto-redirect to the Configure page
  // Sets default `project` value
  useEffect(() => {
    if (isFetchingProject || !projectData) {
      return
    }

    setPageTitle(projectData.name)
    setProject(projectData)
  }, [projectData, isFetchingProject])

  return (
    <Layout title={pageTitle} breadcrumbs={breadcrumbs}>
      {!isFetchingProject && project && (
        <>
          {/* <p className="mt-2 mb-4 max-w-4xl text-sm text-gray-500">
            Please validate the details below to ensure that your cloud resources
            can be created properly.
          </p> */}
          <ProjectPreviewTable project={project} />
        </>
      )}
    </Layout>
  )
}
