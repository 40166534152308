import { AdjustmentsIcon } from '@heroicons/react/solid'
import { useMemo } from 'react'
import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import Card from '../Layout/Card'
import OrganizationPageEnum from '../../enums/OrganizationPageEnum'
import { ROUTES } from '../../helpers/constants'
import { formatUrl } from '../../helpers/functions'
import useGetOrganization from '../../hooks/useGetOrganization'
import { SecondaryButton } from '../Form/Button'
import Layout from '../Layout'
import OrganizationDetails from './OrganizationDetails'

export default function OrganizationsDetailsContainer() {
  const { organizationSlug } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isLoading, data: organizationDetails } =
    useGetOrganization(organizationSlug)
  const currentPage = searchParams.get('page')

  const breadcrumbs = [
    {
      name: organizationDetails?.name,
    },
  ]

  const PageComponent = useMemo(() => {
    switch (currentPage) {
      case null:
        return <OrganizationDetails />
      case OrganizationPageEnum.Projects:
        return <Card>Organization Projects</Card>
      default:
        break
    }

    navigate(formatUrl(ROUTES.ORGANIZATION_DETAILS.GENERAL, organizationSlug))
  }, [currentPage])

  return (
    <Layout
      title={organizationDetails?.name}
      subtitle={organizationDetails?.description}
      breadcrumbs={breadcrumbs}
      primaryButton={
        <SecondaryButton
          onClick={() => {
            navigate(
              formatUrl(ROUTES.ORGANIZATION_SETTINGS.GENERAL, organizationSlug)
            )
          }}
        >
          <AdjustmentsIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Settings
        </SecondaryButton>
      }
    >
      {PageComponent}
    </Layout>
  )
}
