import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useGetMetadata from '../hooks/useGetMetadata'
import { ROUTES } from '../helpers/constants'

export default function Footer() {
  const { isLoading: isLoadingMetadata, data: metadata } = useGetMetadata()
  const [backendVersion, setBackendVersion] = useState('N/A')

  useEffect(() => {
    if (
      !isLoadingMetadata &&
      metadata?.success &&
      Boolean(metadata?.data?.version)
    ) {
      setBackendVersion(metadata.data.version)
    }
  }, [metadata])

  return (
    <footer className="max-w-7xl mx-auto py-6 relative">
      <div
        className="absolute inset-0 flex items-center px-4 sm:px-6 lg:px-8"
        aria-hidden="true"
      >
        <div className="w-full border-t border-gray-200"></div>
      </div>
      <div className="relative flex justify-center text-xs">
        <span className="px-4 bg-gray-100 text-gray-400">
          Copyright &copy; {new Date().getFullYear()} WebDNA Inc. All rights
          reserved.
          <span className="ml-4">Version: {backendVersion}</span>
          <Link to={ROUTES.TERMS} className="ml-4 hover:text-gray-600">
            Terms of Service
          </Link>
          <Link to={ROUTES.PRIVACY} className="ml-4 hover:text-gray-600">
            Privacy Policy
          </Link>
        </span>
      </div>
    </footer>
  )
}
