import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserService from '../../../services/UserService'
import { HOOK_KEY, ROUTES, COLOR } from '../../../helpers/constants'
import useGetUser from '../../../hooks/useGetUser'
import locale from '../../../translations/en'
import { PrimaryButton } from '../../Form/Button'
import FormRow from '../../Form/FormRow'
import Heading from '../../Heading'
import Layout from '../../Layout'
import Card from '../../Layout/Card'
import UserSettingsNav from './UserSettingsNav'
import NotificationContext from '../../../context/NotificationContext'
import NotificationTypeEnum from '../../../enums/NotificationTypeEnum'
import FormLabel from '../../Form/FormLabel'
import Input from '../../_shared/Input'
import Alert from '../../_shared/Alert'
import { useQueryClient } from 'react-query'

export default function UserSettingsGeneral() {
  const userService = UserService.get()
  const queryClient = useQueryClient()
  const { organizationSlug } = useParams()
  const { showNotification } = useContext(NotificationContext)
  const [updatedTimestamp, setUpdatedTimestamp] = useState(null)
  const { isLoading: isLoadingGetUser, data } = useGetUser()
  const [isLoading, setIsLoading] = useState(false)
  const { success, data: userDetails, message } = data || {}
  const [request, setRequest] = useState({
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  })

  const breadcrumbs = [
    {
      name: 'Profile',
    },
    {
      name: 'Settings',
    },
  ]

  const changePassword = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const response = await userService.changePassword(request).catch((e) => {
      if (e?.response?.data?.message) {
        showNotification(
          NotificationTypeEnum.Error,
          e?.response?.data?.message,
          null,
          5000
        )

        setIsLoading(false)
        return
      }
    })

    if (response?.success) {
      showNotification(
        NotificationTypeEnum.Success,
        locale.changePassword.success,
        null,
        3000
      )

      setRequest({
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
      })

      // setUpdatedTimestamp(Date.now())
      queryClient.invalidateQueries({ queryKey: [HOOK_KEY.USER] })
    }

    setIsLoading(false)
  }

  return (
    <Layout
      title="Profile"
      breadcrumbs={breadcrumbs}
      nav={
        <UserSettingsNav
          organizationSlug={organizationSlug}
          currentRoute={ROUTES.USER_SETTINGS.CHANGE_PASSWORD}
        />
      }
    >
      <form onSubmit={changePassword}>
        <Card
          header={<Heading size={3}>Change Password</Heading>}
          footer={
            <div className="text-right">
              <PrimaryButton type="submit" isLoading={isLoading}>
                Save
              </PrimaryButton>
            </div>
          }
        >
          {Boolean(userDetails) && userDetails.password_updated_at === null && (
            <Alert
              title="You must change your temporary password before you proceed"
              className="mb-4"
            />
          )}

          <Alert
            color={COLOR.BLUE}
            title="Your password must include at least each of the following:"
            subtitle={
              <ul className="list-disc ml-4">
                <li>One lower-case letter</li>
                <li>One upper-case letter</li>
                <li>One special character</li>
                <li>One number</li>
              </ul>
            }
            className="mb-4"
          />

          <div className="space-y-6">
            <FormRow
              label={
                <FormLabel htmlFor="old_password">Current Password</FormLabel>
              }
            >
              <Input
                type="password"
                id="old_password"
                name="old_password"
                value={request.old_password}
                onChange={(e) =>
                  setRequest({ ...request, old_password: e.target.value })
                }
                disabled={isLoading || isLoadingGetUser}
                required
              />
            </FormRow>
            <FormRow
              label={<FormLabel htmlFor="new_password">New Password</FormLabel>}
            >
              <Input
                type="password"
                id="new_password"
                name="new_password"
                value={request.new_password}
                onChange={(e) =>
                  setRequest({ ...request, new_password: e.target.value })
                }
                disabled={isLoading || isLoadingGetUser}
                required
              />
            </FormRow>
            <FormRow
              label={
                <FormLabel htmlFor="new_password_confirmation">
                  Confirm New Password
                </FormLabel>
              }
            >
              <Input
                type="password"
                id="new_password_confirmation"
                name="new_password_confirmation"
                value={request.new_password_confirmation}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    new_password_confirmation: e.target.value,
                  })
                }
                disabled={isLoading || isLoadingGetUser}
                required
              />
            </FormRow>
          </div>
        </Card>
      </form>
    </Layout>
  )
}
