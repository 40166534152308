import { DuplicateIcon } from '@heroicons/react/outline'

export default function CopyToClipboard({ text, onCopy }) {
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(text)
    onCopy()
  }

  return (
    <div
      title="Copy to Clipboard"
      className="mr-1 hover:cursor-pointer hover:ring-2 rounded"
    >
      <DuplicateIcon
        className="h-4 w-4"
        onClick={copyToClipboard}
        aria-hidden="true"
      />
    </div>
  )
}
