import {
  CheckIcon,
  ExclamationCircleIcon,
  MinusIcon,
} from '@heroicons/react/solid'
import { classNames, timeAgo } from '../../helpers/functions'
import RunEventStatusEnum from '../../enums/RunEventStatusEnum'
import locale from '../../translations/en'
import { COLOR } from '../../helpers/constants'

export default function LastRunTimeline({ project }) {
  const getIconColor = (event) => {
    let color = ''
    switch (event.status) {
      case RunEventStatusEnum.Success:
        color = `bg-${COLOR.GREEN}-500`
        break
      case RunEventStatusEnum.Error:
        color = `bg-${COLOR.RED}-500`
        break
      case RunEventStatusEnum.Skipped:
        color = `bg-${COLOR.GRAY}-400`
        break
      default:
        color = `bg-${COLOR.YELLOW}-400`
        break
    }

    return color
  }

  const StatusIcon = ({ event }) => {
    switch (event.status) {
      case RunEventStatusEnum.InProgress:
        return (
          <span className="flex relative h-5 w-5" title="In Progress">
            <span className="animate-ping absolute inline-flex h-5 w-5 rounded-full bg-yellow-400 opacity-75"></span>
          </span>
        )
      case RunEventStatusEnum.Success:
        return (
          <span title="Succeeded">
            <CheckIcon className="w-4 h-4 text-white" aria-hidden="true" />
          </span>
        )
      case RunEventStatusEnum.Error:
        return (
          <span title="Error">
            <ExclamationCircleIcon
              className="w-4 h-4 text-white"
              aria-hidden="true"
            />
          </span>
        )
      case RunEventStatusEnum.Skipped:
        return (
          <span title="Skipped">
            <MinusIcon className="w-4 h-4 text-white" aria-hidden="true" />
          </span>
        )
      default:
        return null
    }
  }

  if (!project?.latest_run?.events) {
    return null
  }

  return (
    <section
      aria-labelledby="timeline-title"
      className="lg:col-start-3 lg:col-span-1"
    >
      <div className="bg-white px-4 py-5 shadow sm:rounded-md sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Latest Run
        </h2>

        {/* Activity Feed */}
        <div className="mt-6 flow-root">
          <ul className="-mb-6">
            {project.latest_run.events.map((item, index) => (
              <li key={item.id}>
                <div className="relative pb-6">
                  {index !== project.latest_run.events.length - 1 ? (
                    <span
                      className="absolute top-6 left-3 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          getIconColor(item),
                          'h-6 w-6 rounded-full flex items-center justify-center ring-2 ring-white'
                        )}
                      >
                        <StatusIcon event={item} />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-0.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          {locale.runEventType[item.event_code]}
                        </p>
                        {item.notes && (
                          <p className="italic text-sm text-gray-500 flex">
                            {item.notes}
                          </p>
                        )}
                      </div>
                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                        {timeAgo(item.updated_at)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
